.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 8%;
  border-bottom: 1px solid #eae5ea;
  margin-top: 80px;
}

.footer-left {
  font-size: 14px;
  color: #aaaaaa;
  font-family: "Open Sans", Helvetica;
}

.footer-right {
  display: flex;
  align-items: center;
}

.footer-link {
  margin-right: 20px;
  text-decoration: none;
  color: #7a7a7a;
  font-family: "Open Sans", Helvetica;
  font-weight: 600;
  font-size: 14px;
}

.footer-link:hover {
  text-decoration: underline;
}

.footer-icon {
  margin-right: 20px;
}

.footer-icon img {
  width: 20px;
  height: 20px;
}

@media (max-width: 480px) {
  .footer {
    align-items: center;
    justify-content: center;
  }
  .footer-right {
    display: none;
  }
  .dashboard-logo-api a {
    display: none;
  }
}
