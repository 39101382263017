/* Base styles */
body {
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}

html {
  height: 0 !important;
}

.loading {
  background-color: #FAF9FF;
  width: 100%;
  height: 100vh;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.loading .frame-10 {
  background-color: #FAF9FF;
  align-items: center;
  flex-direction: column;
  padding: 35vh 17% 140px;
  width: 100%;
  gap: 30px;
  display: flex;
  box-sizing: border-box;
}

.loading .frame-11 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.loading .title-5 {
  color: #06283d;
  font-family: "Open Sans", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.4;
  text-align: center;
  width: 100%;
  margin: 0;
  padding: 0 20px;
  box-sizing: border-box;
}

/* Spinner Styles */
@keyframes spinner {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-spinner {
  width: 75px;
  height: 75px;
  border: 9px solid #E1DCED;
  border-top: 9px solid #6750A4;
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.spinner-container {
  margin-top: 20px;
  display: grid;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.loading-message-dynamic {
  font-family: "Open Sans", Helvetica;
  font-size: 20px;
  font-weight: 800;
  color: #6750A4;
  text-align: center;
  padding: 0 20px;
}

/* Back Button Styles */
.back-button {
  padding-top: 134px;
  margin-left: 10%;
  display: flex;
  align-items: center;
  border: none;
  background: none;
  color: black;
  cursor: pointer;
}

.back-button-img {
  height: 24px;
  width: 24px;
}

.back-button-text {
  padding-left: 10px;
  font-family: "Open Sans", Helvetica;
  font-size: 16px;
  font-weight: 500;
}

/* Tablet Styles (768px - 1024px) */
@media (max-width: 1024px) {
  .loading .frame-10 {
      padding: 30vh 10% 100px;
  }

  .loading .title-5 {
      font-size: 15px;
      line-height: 1.3;
      max-width: 90%;
  }

  .loading-message-dynamic {
      font-size: 18px;
  }

  .loading-spinner {
      width: 65px;
      height: 65px;
      border-width: 8px;
  }

  .back-button {
      padding-top: 100px;
      margin-left: 5%;
  }
}

/* Mobile Styles (max-width: 767px) */
@media (max-width: 767px) {
  .loading .frame-10 {
      padding: 25vh 5% 80px;
      gap: 20px;
  }

  .loading .title-5 {
      font-size: 14px;
      line-height: 1.3;
      padding: 0 15px;
  }

  .loading-message-dynamic {
      font-size: 16px;
      padding: 0 15px;
  }

  .loading-spinner {
      width: 55px;
      height: 55px;
      border-width: 7px;
  }

  .spinner-container {
      height: 80px;
      margin-top: 10px;
  }

  .back-button {
      padding-top: 80px;
      margin-left: 5%;
  }

  .back-button-img {
      height: 20px;
      width: 20px;
  }

  .back-button-text {
      font-size: 14px;
  }
}

/* Small Mobile Styles (max-width: 360px) */
@media (max-width: 360px) {
  .loading .frame-10 {
      padding: 20vh 4% 60px;
  }

  .loading .title-5 {
      font-size: 13px;
  }

  .loading-message-dynamic {
      font-size: 15px;
  }

  .loading-spinner {
      width: 45px;
      height: 45px;
      border-width: 6px;
  }
}

/* Handle landscape orientation */
@media (max-width: 767px) and (orientation: landscape) {
  .loading .frame-10 {
      padding: 15vh 5% 40px;
  }

  .back-button {
      padding-top: 60px;
  }
}

/* Prevent text selection during loading */
.loading {
  user-select: none;
  -webkit-user-select: none;
}