.frame-wrapper-matched {
  align-items: center;
  border: 1px solid;
  border-color: #AAAAAA;
  border-radius: 20px;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  padding: 2px 10px;
  position: relative;
}

.frame-wrapper-matched img{
 height: 18px;
 width: 18px;
}

.frame-wrapper-matched .java {
  color: #AAAAAA;
  font-family: "Open Sans", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}
