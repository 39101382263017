.navibar-outer {
    border-right: 1px solid #e5e5ea;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 25%;
    position: sticky;
    top: 64px;
    height: calc(100vh - 64px);
}

.navibar-outer > div:has(.templates-section) {
    display: flex;
    flex-direction: column;
    height: calc(100% - 44px);
    overflow: hidden;
}

.editor-side-bar-container {
    overflow-y: auto;
    padding: 0px 20px 30px 20px;
}

.navibar-control {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    min-height: 50px;
    align-items: center;
}

.navibar-editor,
.navibar-preview {
    flex: 1;
    text-align: center;
    padding: 10px;
    cursor: pointer;
}

.sidebar-container-line{
    width: 100%; /* or set a specific width */
    height: 1px;
    background-color: #e5e5ea; /* color of the line */
    align-self: center;
}

.sidebar-container-line-inside{
    width: 100%; /* or set a specific width */
    height: 1px;
    background-color: #e5e5ea; /* color of the line */
    margin: 10px 0px;
}

.navibar-revision-content {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 10px;
}

.navibar-revision-content-title {
    display: flex;
    margin-bottom: 20px;
    font-weight: 700;
}

.navibar-revision-content-group {
    display: flex;
    flex-direction: column;
    /* border-bottom: 1px solid #E5E5EA; */
}

.navibar-revision-menu-item {
    padding: 8px 12px;
    cursor: pointer;
    color: #7A7A7A;
    font-size: 16px;
    display: flex;
    align-items: center;
    text-align: left;
    position: relative;
    padding-left: 20px;
}

.navibar-revision-menu-item::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 2px;
    background-color: #6750A4;
}

.navibar-revision-menu-item-text:hover {
    color: #6750A4;
    font-weight: 600;
}

.navibar-revision-menu-item-add {
    padding: 8px 12px;
    cursor: pointer;
    color: #7A7A7A;
    font-size: 16px;
    display: flex;
    align-items: center;
    text-align: left;
    position: relative;
    padding-left: 20px;
}

.navibar-revision-menu-item-add::before {
    content: '+';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 27px;
    line-height: 1;
    width: 3px;
    text-align: center;
}

.navibar-revision-menu-item-add:hover {
    /* color: #6750A4; */
    /* font-weight: 600; */
    background-color: #f6f6f6;
    border-radius: 8px;
}

.navibar-keyword-content {
    display: flex;
    flex-direction: column;
    margin: 20px 0px;
}

.navibar-keyword-content-title {
    display: flex;
    margin-bottom: 10px;
    font-weight: 1000;
}

.navibar-keyword-content-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 20px 0;
}

.navibar-keyword-menu-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.navibar-keyword-menu-item,
.navibar-keyword-menu-item-none {
    display: inline-flex;
    padding: 6px 12px;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    text-align: left;
    width: fit-content;
    background-color: #F5F5F5;
}

.navibar-keyword-menu-item-none {
    color: #9E9E9E;
}

.navibar-revision-menu-item-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;  /* This ensures the container takes full width */
}

.navibar-revision-menu-item-text {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-grow: 0;  /* Prevents the text from expanding */
}

.warning-icon {
    width: 20px;
    height: 20px;
    vertical-align: middle;
}

.editor-preview-icon {
    position: absolute;
    transition: opacity 0.2s;
}

.navibar-keyword-suffix {
    margin-left: auto;
    width: 40px;
    text-align: right;
    font-size: 14px;
    margin-left: auto;
}

.navibar-keyword-suffix:hover {
    text-decoration: underline;
}

.navibar-keyword-suffix.check {
    color: #4CAF50;
}

.navibar-save-container {
    display: flex;
    flex-direction: column;
}

.navibar-preview-save-container {
    position: fixed;
    bottom: 0;
    width: 25%;
    background-color: white;
    padding: 20px;
    border-top: 1px solid #e5e5ea;
    z-index: 10;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.navibar-save-btn {
    width: 100%;
    padding: 12px 20px;
    border-radius: 100px;
    border: 2px solid #6750A4;
    background-color: #FFFFFF;
    color: #6750A4;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
}

/* Style for disabled save button */
.navibar-save-btn:disabled {
    background-color: #F5F5F5;
    border-color: #E5E5EA;
    color: #9E9E9E;
    cursor: not-allowed;
}

/* Style for the warning message */
.hint-message {
    color: #DC2626;
    font-size: 14px;
    margin: 0;
    line-height: 1.4;
}

.navibar-save-btn:hover {
    background-color: #F7F7F7;
}

.navibar-keyword-status {
    display: inline-flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}

/* Navibar.css */
.navibar-keyword-menu-container {
    transition: all 0.2s ease;
}

.navibar-keyword-menu-item {
    transition: all 0.2s ease;
    border: solid 1px #FFFFFF;
}

.navibar-keyword-menu-container:hover .navibar-keyword-menu-item {
    color: #6750a4;
    border: solid 1px #6750A4;
}


/* 添加到 Navibar.css */
.navibar-keyword-menu-container {
    position: relative; /* 确保container是定位的父元素 */
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.navibar-keyword-section-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
    z-index: 1000;
    margin-top: 4px;
}

.navibar-dropdown-title {
    padding: 12px 16px;
    font-weight: 600;
    font-size: 14px;
    border-bottom: 1px solid #E5E5EA;
}

.navibar-dropdown-content {
    padding: 8px;
}

.navibar-dropdown-subtitle {
    padding: 8px 8px;
    color: #AAAAAA;
    font-size: 14px;
    cursor: pointer;
    background-color: #F6F6F6;
    border-radius: 8px;
    margin: 4px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center; /* 确保垂直居中 */
}

.navibar-dropdown-subtitle img {
    transition: transform 0.2s ease;
}

.navibar-dropdown-options {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 10;
    margin-top: 4px;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    border: 1px solid #E5E7EB;
    padding: 5px;
}

.navibar-dropdown-option {
    padding: 8px 16px;
    cursor: pointer;
    transition: background-color 0.2s;
    border: 1px solid white;
}

.navibar-dropdown-option:hover {
    border-radius: 8px;
    border: 1px solid #9A82DB;
    background-color: #FAF9FF;
}

.navibar-dropdown-option span {
    color: #333;
    font-size: 14px;
    font-weight: 600;
}

.navibar-ai-option {
    padding: 8px 12px 8px 30px;
    font-size: 14px;
    font-weight: 600;
    border: 1px solid white;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    position: relative;
}

.navibar-ai-option:hover {
    border-radius: 8px;
    border: 1px solid #9A82DB;
    background-color: #FAF9FF;
}

.navibar-back-btn, .navibar-update-btn {
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
}

.navibar-back-word {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 14px;
}

.navibar-ai-option-add-icon {
    position: absolute; 
    left: 12px; 
    top: 50%; 
    transform: translateY(-50%); 
    font-size: 20px; 
    line-height: 1;
}

.navibar-keyword-control-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
    padding: 8px 0;
}

.navibar-icon-trans-control {
    position: relative; 
    width: 16px;
    height: 16px;
}

@media (max-width: 1024px) {
    .new-revision-div {
      overflow-x: hidden;
    }
  
    .new-revision-contents {
      position: relative;
      transition: transform 0.3s ease;
    }
  
    /* Navibar mobile styles */
    .navibar-outer {
      position: fixed;
      left: 0;
      top: 60px;
      width: 40%;
      height: calc(100vh - 64px);
      transform: translateX(-100%);
      transition: transform 0.3s ease;
      background: white;
      z-index: 1000;
      box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    }
  
    .navibar-outer.expanded {
      transform: translateX(0);
    }
  
    /* Toggle button for navibar */
    .navibar-toggle {
      display: flex;
      position: fixed;
      left: 0;
      top: 17%;
      transform: translateY(-50%);
      width: 24px;
      height: 40px;
      background: #6750A4;
      border-radius: 0 4px 4px 0;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      z-index: 999;
      transition: left 0.3s ease;
    }
  
    .navibar-toggle.expanded {
            left: 30%;
    }
  
    .navibar-toggle img {
      width: 12px;
      height: 12px;
      transition: transform 0.3s ease;
    }
  
    .navibar-toggle.expanded img {
      transform: rotate(180deg);
    }
  
    /* Adjust content when navibar is expanded */
    .new-revision-contents.shifted {
            transform: translateX(30%);
    }
  
    /* Navibar internal adjustments */
    .editor-side-bar-container {
      padding: 0 12px 20px;
    }

    .navibar-keyword-content {
        margin-bottom: 40px;
    }
  
    .navibar-revision-content-title,
    .navibar-keyword-content-title {
      font-size: 16px;
    }
  
    .navibar-revision-menu-item,
    .navibar-revision-menu-item-add {
      padding: 8px 8px 8px 16px;
      font-size: 14px;
    }
  
    .navibar-keyword-menu-item,
    .navibar-keyword-menu-item-none {
      font-size: 13px;
      padding: 4px 8px;
    }
  
    .navibar-save-container,
    .navibar-preview-save-container {
      position: fixed;
      bottom: 0;
      width: 100%;
      padding: 12px;
      background: white;
      box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
      left: 0
    }
  
    .navibar-save-btn {
      padding: 8px 16px;
      font-size: 14px;
    }
  
    .hint-message {
      font-size: 12px;
    }
  
    /* Dropdown adjustments */
    .navibar-keyword-section-dropdown {
      width: calc(100% - 24px);
    }
  
    .navibar-dropdown-content {
      padding: 6px;
    }
  
    .navibar-dropdown-subtitle {
      padding: 6px;
      font-size: 13px;
    }
  
    .navibar-dropdown-option {
      padding: 6px 12px;
    }
  
    /* Template section adjustments */
    .templates-section {
      padding: 12px;
    }
  
    .template-list {
      flex-direction: column;
      gap: 12px;
    }
  
    .template-thumbnail {
      width: 100%;
      max-width: none;
    }
  
    /* Overlay for mobile */
    .mobile-overlay {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.5);
      z-index: 999;
    }
  
    .mobile-overlay.active {
      display: block;
    }
  }

  @media (max-width: 600px) {
    .navibar-outer {
        width: 75%;
      }
  }