body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 20px;
    box-sizing: border-box;
  }
html{
  height: 0 !important;
}
.suggested-page {
  align-items: center;
  background-color: #F9FBFC;
  padding: 164px 17% 140px;
  height: auto;
}


.suggested-page .div {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
  border-bottom:1px solid #D3D3D3;
}




.suggested-page .title {
  color: #1f1f1f;
  font-family: "Open Sans", Helvetica;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 43.5px;
  margin-top: -1px;
  position: relative;
  width: 662px;
}



.suggested-page .p {
  color: #1f1f1f;
  font-family: "Open Sans", Helvetica;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}


.suggested-page .line {
  align-self: stretch;
  height: 1px;
  margin-bottom: -1px;
  object-fit: cover;
  position: relative;
  width: 100%;
}


.suggested-page .experience {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 5px;
  position: relative;
  width: 100%;
}

.suggested-page .current {
  align-items: flex-start;
  align-self: stretch;
  background-color: white;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  padding: 15px 20px;
  position: relative;
  width: 100%;
}


.suggested-page .revised {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.suggested-page .expand {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  border-color: #673eab;
  border-left-style: solid;
  border-left-width: 3px;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 10px 20px;
  position: relative;
  width: 100%;
  cursor: pointer;
}


.suggested-page .img {
  height: 24px;
  position: relative;
  width: 24px;
  cursor: pointer;
}

.suggested-page .line-2 {
  height: 1px;
  object-fit: cover;
  position: relative;
  width: 800px;
}




/* new CSS updates: 06/16 */

  .common-block-container {
    border: 1px solid #E5E5EA;
    border-radius: 4px;
    padding: 20px;
    /* box-shadow: 0 2px 4px rgba(0,0,0,0.1); */
    margin-bottom: 30px;
    background-color: #FFFFFF;
  }

  .company-analysis-content {
    color:#2A2A2A;
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    margin-left: 30px;
  }

  .scanning_title {
    font-size: 28px; /* Adjust size as needed */
    font-weight: bold; /* Makes the text bold */
    margin-bottom: 30px; /* Adds space below the text */
  }

  .company-analysis-heading {
    color: #6750A4;
    font-family: "Open Sans";
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    margin-bottom: 30px;
    margin-left: 30px;
  }

  .company-analysis-heading img {
    vertical-align: middle;
    margin-right: 8px;
  }

  .company-analysis-heading span {
    display: inline-block;
    vertical-align: middle; /* Ensures the baseline of the text aligns with the middle of the icon */
  }

  .navigation-bar {
    display: flex;
    overflow-x: auto; /* Allows scrolling if many items */
    white-space: nowrap; /* Keeps buttons in a single line */
    background-color: #F9FBFC; /* Default background color for the entire bar */
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 30px;
  }
  
  .navigation-bar button {
    flex-grow: 1;
    padding: 10px 20px;
    cursor: pointer;
    background-color: transparent; /* Make background transparent to inherit from navbar */
    color: #7A7A7A;
    border: none; /* Remove borders */
    outline: none; /* Remove focus outline */
    transition: background-color 0.3s; /* Smooth transition for background color */
    border-radius: 10px; /* Rounded corners */
    font-weight: 600; /* Make the text bold */
  }
  

  .navigation-bar button:hover, .navigation-bar button.active {
    background-color: #F0EDFF; /* Darker shade when active */
    color: #6750A4; /* Change text color when active */
  }

  .navigation-bar button:not(:last-child) {
    border-right: 1px solid #F6F6F6; /* Optional: adds a subtle separator between buttons */
  }

  .experience-details{
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 30px;
  }


  .score-container {
    display: flex;
    align-items: flex-start; /* Keeps items aligned at the top */
    gap: 20px;
    background-color: #faf9ff;
    margin-bottom: 30px;
    border-radius: 6px;
}

.text-container {
    display: flex;
    flex-direction: column; /* Stacks children vertically */
    align-items: flex-start; /* Aligns text to the left */
    margin-top: 20px;
}

.matching-score-text {
    font-size: 18px; /* You can adjust sizes individually if needed */
    font-weight: bold;
    color: #6750A4; /* Matching score text color */
    margin-top: 0; /* Removes any top margin for a clean start */
}

.additional-text {
    font-size: 14px; /* Slightly smaller, adjust as needed */
    color: #2a2a2a; /* More standard text color, adjust as needed */
    margin-top: 5px; /* Adds a little space between the texts */
    line-height: 1.4;
}

.current-experience-container {
    border: 1px solid #EAE5EA;
    border-radius: 4px;
    /* box-shadow: 0 2px 4px rgba(0,0,0,0.1);  */
    margin-bottom: 30px;
    padding: 20px;
}

.experience_title {
color: #7A7A7A;
font-family: "Open Sans";
font-weight: bold;
margin-bottom: 10px;
font-size: 18px;
}

.experience_duration {
    color: #7A7A7A;
    font-family: "Open Sans";
    margin-bottom: 10px;
    font-size: 16px;
}

.experience-description-list-item {
    margin-bottom: 10px; /* Adds space between list items */
    text-align: left; /* Ensures text is aligned to the left */
    text-indent: -20px; /* Optional: Adjusts text start position relative to the bullet */
    padding-left: 30px; /* Aligns text after bullet when using text-indent */
    line-height: 1.2;
    color: #7A7A7A;
}

.company-analysis-heading img,
.analysis_keywords_heading img,
.analysis_soft_skills_heading img,
.analysis_verbs_heading img,
.analysis_bullet_points_heading img{
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

/* ----------------------------------------------Analysis Section-------------------------------------------- */
.experience_analysis_container{
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.analysis_keywords,
.analysis_soft_skills,
.analysis_verbs,
.analysis_bullet_points{
  display: flex;
  flex-direction: column;
  gap:10px;
}

.flex-line {
  flex: 1; /* Makes the line take up available space */
  border: none; /* Remove default border */
  border-top: 1px solid #F6F6F6; /* Custom border style */
  margin: 0px;
  opacity: 1;
}

.company-analysis-heading,
.analysis_keywords_heading,
.analysis_soft_skills_heading,
.analysis_verbs_heading,
.analysis_bullet_points_heading{
  display: flex;
  flex-direction: row;
  align-items: center;
  color:#2A2A2A;
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  font-weight: 600;
}

.analysis_bullet_points p{
  font-size: 14px;
  color: #AAAAAA;
}

.analysis_bullet_points_content{
  display: flex;
  flex-direction: column;
  gap: 10px;
}


.analysis_soft_skills_content,
.analysis_verbs_content,
.bullet_point1{
  background-color: #F7F7F7;
  border-radius: 8px;
  padding: 15px;
  line-height: 1.2;
}

.analysis_keywords_content{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 7px;
}

.bullet_points_content{
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.bullet_point1{
  display: flex;
  flex-direction: row;
  gap: 20px;
  cursor: pointer; /* This will change the cursor to a pointer when hovering */
}

.bullet_point1.checked {
  border: 2px solid #6750A4; /* Border color when checked */
  background-color: #FAF9FF; /* Background color when checked */
}

.checkbox_aspect_6 {
    cursor: pointer;
}


.separate-text {
    margin-bottom: 30px;
    color: #2A2A2A;
    font-weight: 600;
}

.suggests-grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Creates three columns of equal width */
    gap: 20px; /* Space between grid items */
    padding: 10px; /* Padding around the grid */
}

/*check box*/
.side-card-checkbox {
    display: block;
    position: relative;
    padding-left: 35px; /* Space for the checkbox */
    margin-bottom: 20px; /* Space below the header */
    cursor: pointer;
    font-size: 20px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .side-card-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .side-card-checkbox .side-card-header:before {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #e2d8f4;
    border-radius: 4px;
    content: '';
  }

  /* On mouse-over, add a grey background color */

  /*
  /* When the checkbox is checked, add a blue background */
  .side-card-checkbox input:checked ~ .side-card-header:before {
    background-color: #673eab;
  }

  .side-card-checkbox:hover input ~ .side-card-header:before {
    background-color: #868587;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .side-card-checkbox .side-card-header:after {
    content: '✓';
    position: absolute;
    color: white;
    top: -2px;
    left: 5px;
    width: 25px;
    height: 25px;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
  }

  /* Show the checkmark when checked */
  .side-card-checkbox input:checked ~ .side-card-header:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .side-card-checkbox .side-card-header:before,
  .side-card-checkbox .side-card-header:after {
    border-radius: 4px;
  }

  .card,
  .side-card {
    border: 1.5px solid #6750A4;
    border-radius: 4px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Adds a subtle shadow for depth */
    background-color: #faf9ff;
  }

  .side-card-header {
    font-size: 16px;
    color: #6750A4;
    margin-bottom: 10px;
    font-weight: 600;
  }

  .side-card-text {
    line-height: 1.2;
  }

  .added_description {
    font-weight: 600;
    color: #6750A4;
    margin-bottom: 20px;
  }

  .button-container {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically, if needed */
}

.suggested-edits-result-button {
    width: 700px; /* Example fixed width */
}

.checkbox-container {
    display: block;
    position: relative;
    padding-left: 20px;
    margin-bottom: 12px;
    cursor: pointer;
    user-select: none;
  }

  .checkmark {
    line-height: 1.2;
    font-weight: 500;
  }

   /* ---------------------------score ---------------------*/
 .score {
    background-color: #FFFFFF;
   border: #E5E5EA 1px solid ;
    border-radius: 8px;
    margin: 15px 0;
    display: flex;
    align-items: center;
}

.score-text{
   margin: 0 20px 0 0;
}

.score-text h4{
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 4px;
}


.score-text p {
    font-size: 14px;
    color: #7A7A7A;
    line-height: 1.5;
}


@media (max-width: 1024px) {
  .suggested-page {
    padding: 164px 4% 60px;
  }
}

@media (max-width: 610px) {
  .suggested-page {
    padding: 100px 16px 40px;
  }

  .scanning_title {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
  }

  .common-block-container {
    padding: 15px;
    margin-bottom: 20px;
  }

  .company-analysis-heading {
    font-size: 18px;
    margin-left: 10px;
    margin-bottom: 20px;
  }

  .company-analysis-content {
    font-size: 14px;
    margin-left: 10px;
    line-height: 1.5;
  }

  .navigation-bar {
    margin: 0 0 20px;
    padding: 5px;
    flex-wrap: wrap;
    justify-content: center;
  }

  .navigation-bar button {
    flex: 0 0 auto;
    min-width: 45%;
    margin: 5px;
    padding: 8px 12px;
    font-size: 14px;
    white-space: normal;
  }

  .experience-details {
    margin: 0;
  }

  .score-container {
    flex-direction: column;
    align-items: center;
    gap: 0px;
    padding: 15px;
  }

  .text-container {
    margin-top: 10px;
    text-align: center;
    align-items: center;
  }

  .matching-score-text {
    font-size: 16px;
  }

  .additional-text {
    font-size: 13px;
    text-align: center;
  }

  .current-experience-container {
    padding: 15px;
  }

  .experience_title {
    font-size: 16px;
  }

  .experience_duration {
    font-size: 14px;
  }

  .experience-description-list-item {
    font-size: 14px;
    padding-left: 25px;
    text-indent: -15px;
  }

  .analysis_keywords_content {
    gap: 5px;
  }

  .company-analysis-heading,
  .analysis_keywords_heading,
  .analysis_soft_skills_heading,
  .analysis_verbs_heading,
  .analysis_bullet_points_heading {
    font-size: 16px;
  }

  .analysis_soft_skills_content,
  .analysis_verbs_content,
  .bullet_point1 {
    font-size: 14px;
    padding: 12px;
  }

  .bullet_point1 {
    flex-direction: column;
    gap: 10px;
  }

  .suggests-grid-container {
    grid-template-columns: 1fr;
    gap: 15px;
  }

  .side-card {
    margin-bottom: 15px;
  }

  .side-card-header {
    font-size: 15px;
  }

  .side-card-text {
    font-size: 14px;
  }

  .button-container {
    margin-top: 30px;
  }

  .suggested-edits-result-button {
    width: 100%;
    max-width: 320px;
  }

  /* Ring score component adjustments */
  .score {
    flex-direction: column;
    padding: 15px;
    text-align: center;
  }

  .score-text {
    margin: 15px 0 0;
  }

  .score-text h4 {
    font-size: 16px;
  }

  .score-text p {
    font-size: 13px;
  }

  /* Frame wrapper adjustments */
  .keywords_matched,
  .keywords_unmatched {
    font-size: 13px;
    padding: 6px 12px;
  }
}