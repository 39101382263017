.cl-popup-main {
    gap: 30px;
    flex-direction: column;
    /* min-height: 100vh; */
    /* margin: 0 8%; */
    /* padding: 25px 9%; */
    display: flex;
}

.cl-popup-copy-img {
    height: 30px;
    width: 30px;
    margin-bottom: 10px;
    cursor: pointer;
}

.cl-popup-export-button {
    justify-content: flex-end;
    align-items: center;
    margin-top: -35px;
    display: flex;
    margin-bottom: 10px;
}

.popup-export-button {
    width: 30px;
    height: 30px;
    cursor: pointer;
    margin-bottom: 10px;
}

.popup-img-box {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 20px;
}