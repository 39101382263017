.features {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 20px;
  max-width: 1080px;
}

.feature {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 7px 8px 24px rgba(172.62, 151.28, 207.42, 0.24);
  text-align: left;
}

.icon {
  max-width: 30px;
  margin-bottom: 10px;
}

.feature_title {
  font-size: 1.2em;
  margin: 10px 0;
  font-family: "Open Sans", Helvetica;
  font-weight: 700;
}

.feature_content {
  font-size: 1em;
  line-height: 1.5;
  color: black;
  font-family: "Open Sans", Helvetica;
}

@media (max-width: 768px) {
  .features {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .features {
    grid-template-columns: 1fr;
    font-size: 14px;
  }
}
