 /* Main box styles */
 .contents{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #F9FBFC;
    width: 100%;
    /* Account for the navigation height (62px) */
    min-height: calc(100vh - 62px);
    margin-top: 62px;
    flex: 1; /* Take up remaining space */
}

.main-box {
    width: 75%;
    transition: width 0.3s ease;
}

.resu-results {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.hint-text {
    color: red;
    margin-bottom: 10px;
}

.main-section{
    display: flex;
    flex-direction: column;
    max-width: 85%;
    margin: 40px auto;
}

.dashboard-sidebar-buttons-section {
    margin-top: 30px;
}

.dashboard-sidebar-continue-revision-section {
    margin-top: 10px;
}

.dashboard-save-button {
    width: 100%; /* Makes the button take up the full width of the sidebar */
    padding: 14px 10px; /* Increases vertical space (top/bottom) and keeps horizontal padding */
    text-align: center; /* Centers the text inside the button */
    border: solid 2px #6750A4; /* Border color */
    background-color: #FFFFFF; /* Button background color */
    color: #6750A4; /* Button text color */
    cursor: pointer; /* Changes cursor to pointer on hover */
    margin-bottom: 12px;
    border-radius: 10px; /* Makes the corners rounded */
    font-weight: bold;
}

button.dashboard-save-button:hover {
    cursor: pointer;
    background-color: #ffffff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

button.dashboard-save-button:disabled {
    cursor: not-allowed;
    box-shadow:none;
    border: 2px solid #c7c7c7;
    background-color: #fff;
    transition: none;
    color:#c7c7c7;
}

button.dashboard-continue-button {
    width: 100%; /* Makes the button take up the full width of the sidebar */
    padding: 14px 10px; /* Increases vertical space (top/bottom) and keeps horizontal padding */
    text-align: center; /* Centers the text inside the button */
    background-color: #6750A4; /* Button background color */
    color: #FFFFFF; /* Button text color */
    cursor: pointer; /* Changes cursor to pointer on hover */
    margin-top: 10px;
    margin-bottom: 20px;
    border-radius: 10px; /* Makes the corners rounded */
    font-weight: bold;
    border: 2px solid #6750a4;
}

button.dashboard-continue-button:hover {
    cursor: pointer;
    background-color: #381E72;
    border: 2px solid #381E72;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

button.dashboard-continue-button:disabled {
    cursor: not-allowed;
    border: 2px solid #c7c7c7;
    background-color: #C7c7c7;
    transition: none;
    color:#AAAAAA;
    box-shadow:none;
}
.dashboard-experience-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.dashboard-editor-back-button{
    background-color: none;
    color: #2a2a2a;
    border: none;
    align-items: center;
    margin: 30px 40px;
    display: flex;
    width: fit-content;
}

.dashboard-editor-back-button .back-button-text{
    font-size: 16px;
    padding-left: 8px;
    font-weight: 600;
}

 /* ------------------------------Notice --------------------- */
 .notice {
    margin: 5px 0;
}

.description .help-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
}

.description .help {
    background-color: #F0EDFF;
    border-radius: 40px;
    height: 32px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.description .help button {
    background: none;
    border: none;
    color: black;
    cursor: pointer;
    font-size: 14px;
    margin-right: 4px;
}

.description .help img {
    width: 18px;
    height: 18px;
}

.notice .experience-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.notice h4 {
    font-size: 20px;
    font-weight: bold;
}

 /* -------------------Description ----------------------- */
 .description {
    display: flex;
    flex-direction: column; /* Arrange children in a column */
    justify-content: space-between; /* Space out content, pushing the button to the bottom */
    border: #E5E5EA 1px solid;
    padding: 20px;
    background-color: #FFFFFF;
    border-radius: 8px;
    margin-bottom: 5px;
    height: 100%; /* Ensure that the height of the container is controlled */
}
 
 .description p {
     font-size: 16px;
     font-weight: 600;
     margin-bottom: 10px;
 }
 
 .revised-content{
     /* border: 1px solid #E5E5EA; */
     /* padding: 10px; */
     border-radius: 8px;
     position: relative;
     font-size: 16px;
     line-height: 1.5;
     margin-bottom: 20px;
 }

 .current-content {
    border: 1px solid #E5E5EA;
    background-color: #F9FBFC;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
    position: relative;
    font-size: 16px;
    line-height: 1.5;
    color: #AAAAAA;
}
 
 .revised-content .img {
     position: absolute;
     top: 20px;
     right: 10px;
     width: 20px;
     height: 20px;
     cursor: pointer;
 }
 
 .revised-content .revised-time,
 .current-content .current-time{
     font-weight: 400;
 }
 .revised-list ul,
 .current-list ul {
     list-style-type: disc;
     margin-left: 20px;
     
 }
 
 .revised-list li,
 .current-list li {
     line-height: 1.2;
     padding-bottom: 10px;
 }

  /* ------------------------------Summary--------------------------------- */
 
  .summary-container {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    border: 1px  #E5E5EA solid;
    max-width: 85%;
    min-height: 200px;
    margin: 20px auto;
    
  
}
.inner-container{
    min-height: 100px;
}

.summary-title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #2A2A2A;
}

.summary-textarea {
   width: 100%;
   min-height: 40px; /* Initial height */
   max-height: 200px;
   border: 1px solid #E5E5EA;
   padding: 8px;
   font-size: 16px;
   line-height: 1.5;
   color: #2A2A2A;
   font-weight: 400;
   border-radius: 8px;
   resize: none; /* Prevent manual resizing */
   box-sizing: border-box; /* Include padding in height calculation */
   margin-top: 10px;
}

.inner-container .generate_button {
    display: block;
    margin: 40px auto;
}

.summary-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.summary-title {
    flex: 1;
}

/* ------------------------------Personal Information--------------------------------- */
.personal-content {
    max-width: 85%;
    margin: 20px auto;
}

.detail-item-personal {
    margin-bottom: 18px;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    border: 1px #E5E5EA solid;
}

.form-label {
    display: block;
    font-size: 16px;
    /* font-weight: 600; */
    margin-bottom: 10px;
}

.form-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #E5E5EA;
    border-radius: 8px;
    font-size: 16px;
    /* font-weight: 600; */
    line-height: 1.5;
}

/* ------------------------------Skills--------------------------------- */

.skills-container {
    border-radius: 8px;
    padding: 20px;
    max-width: 85%;
    margin: 20px auto;
}

.detail-item-skills {
   margin-bottom: 18px;
   padding: 20px;
   background-color: white;
   border-radius: 8px;
   border: 1px #E5E5EA solid;
}

.skills-title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
    color: black;
    
}

.jd-keywords-frame {
   display: flex;
   flex-wrap: wrap;
   gap: 8px;
   align-items: center;
}


/* ------------------------------Educations--------------------------------- */
.dashboard-education-header{
   justify-content: space-between;
   align-items: center;
   margin-top: 5px;
   margin-bottom: 20px;
   display: flex;
}

.education-container{
    max-width: 85%;
    margin: 40px auto;

}

.detail-item {
   /* margin-bottom: 18px; */
   padding: 10px 10px 10px 0;
   background-color: white;
   border-radius: 8px;
   /* border: 1px #E5E5EA solid; */
}

.edu-content{
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    border: 1px #E5E5EA solid;
    margin-bottom: 20px;
}
.edu-title{
    font-size: 20px;
    font-weight: 700;
}
.detail-item-personal{
    margin-bottom: 20px;
}
.detail-item-personal .form-label{
    margin-bottom: 10px;
   
}

/* -----------------------------Certificates --------------------------------- */

.dashboard-button-container{
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.certificate-container {
   background-color: white;
   border-radius: 8px;
   padding: 20px;
   border: 1px #E5E5EA solid;
   max-width: 85%;
   margin: 20px auto;
}

.add-certificate-button {
   border-radius: 8px;
   background-color: #f9fbfc;
   color: #c7c7c7;
   width: 85%;
   font-size: 16px;
   font-weight: bold;
   padding-top: 12px;
   padding-bottom: 12px;
   border: 2px solid #c7c7c7;
   cursor: pointer;
}

.add-skills-button{
    border-radius: 40px;
    background-color: #C7C7CC;
    color: white;
    width: 95%;
    font-size: 16px;
    font-weight: bold;
    padding-top: 12px;
    padding-bottom: 12px;
    border: #C7C7CC;
    cursor: pointer;

}

.button-container .add-certificate-button:hover{
    color: #6750A4;
    border: 2px solid #6750A4;
}

.button-container .add-skills-button:hover{
    color: #6750A4;
}

.certificate-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 85%;
    margin: 20px auto;
}

.certificate-title {
    flex: 1;
    font-weight: 600;
}

/* ------------------------------Sidebar --------------------------------- */
.sidebar {
    width: 25%;
    background-color: #FFFFFF;
    border: #E5E5EA 1px solid;
    padding: 40px 20px;
    position: relative;
    transition: all 0.3s ease;
    overflow-y: auto;
}

.sidebar-toggle {
    display: none;
    position: fixed;
    top: 80px;
    right: 20px;
    z-index: 1000;
    background-color: #6750A4;
    color: white;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
}

.sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #E5E5EA;
}

.close-sidebar {
    background: none;
    border: none;
    cursor: pointer;
    padding: 8px;
    margin-top: 20px;
    margin-left: auto; /* This will push the button to the right */
}

.close-sidebar img {
    width: 24px;
    height: 24px;
}


.sidebar h3 {
    font-size: 20px;
    font-weight: bold;
    padding: 0 10px;
   

}
.nextStep {
    position: relative;
   
}
.sidebar .view-full{
    width: 100%;
    margin: 20px 0;
    border: #6750A4 2px solid;
    border-radius: 30px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: #FFFFFF;
    background-color: #6750A4;
    padding: 12px 15px;
}
.sidebar .view-full:hover{
    color: white;
    background-color: #381E72;
}

.side-list button {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 12px 10px;
    border-bottom: none;
    border-left: none;
    border-right: none;
    border-top: 2px solid #F6F6F6;
    background-color: transparent;
    color: #7A7A7A;
    font-size: 18px;
    cursor: pointer;
    border-radius: 4px;
    text-align: left;
    margin-bottom: 10px;
}

.side-list button.experience-button {
   display: flex;
   align-items: center;
   width: 100%;
   padding: 12px 10px;
   border-bottom: none;
   border-left: none;
   border-right: none;
   border-top: 2px solid #F6F6F6;
   background-color: transparent;
   color: #7A7A7A;
   font-size: 18px;
   cursor: pointer;
   border-radius: 4px;
   text-align: left;
   margin-bottom: 10px;
   margin-top: 40px;
}


.side-list button:first-of-type {
    border-top: none;
}

.side-list button img {
    margin-right: 12px;
    height: 20px;
}

.side-list button:hover {
    /* background-color: #F0EDFF !important; */
    color: #6750A4;
    border-radius: 40px;
    font-weight: 600;
}



.side-list ul {
    list-style-type: none;
    padding: 0;
}



.side-list ul button {
    color: #7A7A7A;
    font-size: 16px;
    text-align: left;
    margin-bottom: 0;
    line-height: 1.1;
}
.side-list ul .add {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
   border-radius: 40;
    border: 1px #E5E5EA solid;
    margin-bottom: 12px;
    padding: 8px 0;

   
}

.side-list ul button:hover {
    /* background-color: #FAF9FF !important; */
    font-weight: 600;
    color: #000;
}

/*--------------------Experience-----------------------*/
.experience-form-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #E5E5EA;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 10px;
}

.experience-textarea {
    width: 100%; /* Make the textarea take the full width of its container */
    padding: 10px; /* Add some padding for better readability */
    padding-right: 30px; /* Adjust this value to ensure the text doesn't overlap the image */
    border: 1px solid #ccc; /* Set a border color */
    border-radius: 4px; /* Add some border radius for rounded corners */
    font-size: 16px; /* Set the font size */
    font-weight: 400;
    line-height: 1.5; /* Set the line height */
    box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
    resize: vertical; /* Allow vertical resizing but not horizontal */
    overflow: auto; /* Allow scrolling if content exceeds the set height */
}

/*--------------------Skills - category------------------------*/
.skills-category-container {
   position: relative; /* The container is positioned relative to itself */
   width: fit-content; /* Ensures the width of the container adjusts to the button's width */
   z-index: 1; /* Ensure the container and its contents are above other elements */
   margin-bottom: 10px;
}

.skills-category-button-container{
   display: flex; /* Align children in a row */
   align-items: center; /* Vertically center the items */
   gap: 10px; /* Add some space between the buttons */
}

.skills-category-button {
   display: flex;
   align-items: center;
   padding: 4px 14px;
   background-color: white;
   border-radius: 8px;
   border: 1px #E5E5EA solid;
   align-items: center;
   cursor: pointer;
   width: 100%; /* The button will take up the full width of the container */
}

.skills-category-button.active {
   display: flex;
   color: #6750A4;
   align-items: center;
   padding: 4px 14px;
   background-color: #FAF9FF;
   border-radius: 8px;
   border: 1px #6750A4 solid;
   align-items: center;
   cursor: pointer;
   width: 100%; /* The button will take up the full width of the container */
}

.reset-category-button {
   padding: 0; /* Remove padding to make it appear as plain text */
   background-color: transparent; /* Remove the background color */
   border: none; /* Remove the border */
   cursor: pointer; /* Keep the cursor as a pointer to indicate it's clickable */
   color: #2A2A2A; /* Set the text color */
   text-decoration: underline; /* Add underline to the text */
   white-space: nowrap; /* Prevent text from wrapping to the next line */
   font-size: 1rem; /* Adjust the font size as needed */
   font-weight: 500;
}

.sort-icon {
   margin-right: 8px;
   height: 18px;
   width: 18px;
}

.dropdown-list-guide {
   padding: 5px 5px;
   background-color: #f7f6ff;
   border-radius: 8px;
   width: 100%;
   font-size: 14px;
   line-height: 1.3;
}
.dropdown-list-header{
   font-size: 16px;
   color: #2A2A2A;
   font-weight: 600;
   padding: 5px;
}

.dropdown-list {
   padding: 20px 10px;
   border: 1px solid #F6F6F6;
   border-radius: 8px;
   background-color: #FFFFFF;
   position: absolute;
   top: 120%; /* Position the dropdown directly below the button */
   left: 0;
   max-width: 300px;
   width: 100%; /* Match the width of the parent container */
   box-sizing: border-box; /* Include padding and border in the element's width */
   z-index: 10; /* Ensure the dropdown is above other content */
   display: flex;
   flex-direction: column; /* Arrange children vertically */
   align-items: flex-start; /* Center the button horizontally */
   gap: 10px;
}

.category-dropdown-item {
   display: flex;
   align-items: center;
   gap: 8px;
   padding: 8px 4px;
   font-weight: 600;
   width: 100%;
   color: #2A2A2A;
   border-radius: 8px;
   cursor: pointer;
}

.category-dropdown-item:hover {
   background-color: #f6f6f6;
   cursor: pointer;
}


.save-category-button {
   margin-top: 10px;
   padding: 8px 10px;
   font-weight: 600;
   border: none;
   border-radius: 8px;
   background-color: #6750A4;
   color: white;
   cursor: pointer;
   width: 100%; /* Make the save button the same width as the dropdown */
}

.save-category-button:disabled {
   background-color: #E5E5EA;
   cursor: not-allowed;
}

.selected-category-skill{
   margin-bottom: 10px;
}

/*--------------------Skills - checkmark-----------------------*/

.category-dropdown-item input[type="checkbox"] {
   position: absolute;
   opacity: 0;
   cursor: pointer;
   height: 0;
   width: 0;
}

.category-dropdown-item .checkmark {
   position: relative;
   display: inline-block;
   width: 18px;
   height: 18px;
   background-color: #ffffff;
   border-radius: 4px; /* Rounded corners */
   border: 2px solid #E5E5EA;
   transition: background-color 0.2s ease, border-color 0.2s ease;
}

/* When the checkbox is checked, style the checkmark */
.category-dropdown-item input[type="checkbox"]:checked + .checkmark {
   background-color: #6750A4;
   border-color: #6750A4;
}

/* Create the checkmark/indicator (hidden by default) */
.category-dropdown-item .checkmark::after {
   content: "";
   position: absolute;
   display: none;
   left: 4px;
   top: 0px;
   width: 6px;
   height: 12px;
   border: 2px solid #ffffff;
   border-width: 0 2px 2px 0;
   transform: rotate(45deg);
}

/* Show the checkmark when the checkbox is checked */
.category-dropdown-item input[type="checkbox"]:checked + .checkmark::after {
   display: block;
}

/*--------------------General-----------------------*/
.delete-button {
    background: none;
    border: none;
    color: #0f0f0f;
    text-decoration: underline;
    cursor: pointer;
    font-size: 16px;
    padding: 0;
}

.view-full.disabled {
    background-color: #ccc;
    color: #666;
    cursor: not-allowed;
    border-color: #ccc;
}
.view-full.disabled:hover{
    background-color: #ccc ;
    color: #666;

}

.starColor {
    color: red;
}

.divider-line {
   border: none;
   border-top: 1px solid #bdbdbd; /* Grey line */
   margin: 10px 0; /* Space above and below the line */
}

.coverletter-generate-button {
   display: flex; /* Flexbox for horizontal alignment */
   align-items: center; /* Vertically center the icon and text */
   justify-content: center; /* Center content horizontally (if needed) */
   align-self: flex-end; /* Align the button to the right */
   padding: 10px 20px; /* Adjust padding */
   color: #2A2A2A; /* Button text color */
   border: 1px solid #2A2A2A; /* Button border */
   border-radius: 8px; /* Round the button corners */
   cursor: pointer; /* Pointer cursor on hover */
   background-color: #fff; /* Background color */
   font-size: 16px; /* Font size */
   font-weight: 600; /* Font weight */
}

.coverletter-generate-button:hover {
   background-color: #FAF9FF;
   border: solid 1px #6750A4;
}

.coverletter-generate-button:disabled {
   border: solid 1px #AAAAAA;
   background-color: #AAAAAA;
}

.coverletter-generate-textarea {
   width: 100%;
   font-size: 16px;
   padding: 10px;
   border: 1px solid #ccc;
   border-radius: 8px;
   resize: vertical; /* Allow vertical resizing */
   line-height: 1.5;
}

@media (max-width: 1024px) {
    .main-box {
        width: 100%;
        padding: 0 20px;
    }

    .sidebar {
        position: fixed;
        top: 0;
        left: -100%;  /* Changed from right to left */
        width: 80%;
        max-width: 400px;
        z-index: 1000;
        height: 100%;
        box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1);  /* Changed shadow direction */
    }

    .sidebar.visible {
        left: 0;  /* Changed from right to left */
    }

    .sidebar-toggle {
        display: flex;
    }

    .sidebar-header {
        display: flex;
    }

    .side-list {
        margin-top: 20px;
    }

    .skills-container {
        max-width: 100%;
    }
}

/* Overlay styles */
.sidebar-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.sidebar-overlay.visible {
    display: block;
}