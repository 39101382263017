.dashboard-upload {
    background-color: #FFFFFF;
    position: relative;
    width: 100%;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.dashboard-return-button {
    margin-top: 16vh;
    margin-left: 16vh;
    background: none;
    border: none;
    color: #000; /* Change color as needed */
    cursor: pointer;
    font-size: 16px; /* Adjust font size as needed */
    display: flex; /* Use flexbox to align image and text */
    align-items: center; /* Align the image and text vertically */
    gap: 8px; /* Add space between the image and the text */
}

.dashboard-button-icon {
    width: 16px; /* Adjust the size of the image */
    height: 16px; /* Adjust the size of the image */
}

.dashboard-upload .title {
    align-items: flex-start;
    display: inline-flex;
    margin-top: 20px;
    width: 100%;
}

.dashboard-upload .title-2 {
    color: #000000;
    font-family: "Open Sans", Helvetica;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 43.5px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.dashboard-upload .text-3 {
    color: #000000;
    font-family: "Open Sans", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    text-align: center;
    width: 63px;
}

.dashboard-upload .navigation-instance {
    left: 0 !important;
    position: absolute !important;
    top: 0 !important;
}

.dashboard-upload-main {
    background-color: #ffffff;
    align-items: top;
    display: flex;
    flex-direction: column;
    gap: 46px;
    justify-content: top;
    margin: 0 13.5%;
    padding: 16vh 9% 8vh;
    min-height: 100vh;
}

.dashboard-upload .switch-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}

.switch-text {
    color: #9A82DB;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
}


.dashboard-upload-box {
    margin-top: -12px;
}

.dashboard-upload .div-2 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    position: relative;
    width: 100%;
    margin-top: 12vh;
    margin-bottom: 2vh;
    padding-left: 18%;
    padding-right: 18%;
}

.dashboard-upload .button-upload-section {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    position: relative;
    width: 100%;
    padding: 4vh 18% 2vh;
}

.dashboard-upload .title-3 {
    color: #000000;
    font-family: "Open Sans", Helvetica;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24.5px;
    margin-top: -1px;
    position: relative;
}

.dashboard-upload .upload-field {
    align-items: center;
    border: 2px #9A82DB dashed;
    background-color: #FAF9FF;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    position: relative;
    width: 100%;
    padding: 30px;
    min-height: 200px;
    justify-content: center;
}

.dashboard-upload .upload-field.selected {
    border: 2px #AAAAAA dashed;
    background-color: #ffffff;
}

.dashboard-upload .upload-cloud {
    height: 46px;
    position: relative;
    width: 50.73px;
    justify-self: center;
}

.dashboard-upload .p {
    color: #aaaaaa;
    font-family: "Open Sans", Helvetica;
    font-size: 11px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 15px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.dashboard-upload .rectangle {
    align-self: stretch;
    border: 1px solid;
    border-color: #c7c7c7;
    border-radius: 10px;
    height: 248px;
    position: relative;
    width: 100%;
    padding: 10px;
    font-family: "Open Sans", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 17.7px;
}

.custom-quill-editor {
    /* border: 1px solid #c7c7c7;*/
    border-radius: 10px; /* Set rounded corners */
    height:100%;
    font-family: "Open Sans", Helvetica; /* Set font-family */
    font-size: 16px; /* Set font-size */
    line-height: 17.7px; /* Set line height */
    width: 100%; /* Make the width full */
}

/* Ensure the Quill container respects the height and fills the space */
.custom-quill-editor .ql-container {
    max-height: 250px; /* Make the container fill the height */
}

/* Override the inner editor's style to match your rectangle design */
.custom-quill-editor .ql-editor {
    font-family: "Open Sans", Helvetica; /* Font family */
    font-size: 13px; /* Font size */
    line-height: 17.7px; /* Line height */
    min-height: 100px; /* Fill the parent height */
    max-height: 250px;
    padding: 10px; /* Apply padding to match the rectangle style */
    letter-spacing: 0; /* Adjust letter spacing */
    overflow-y: auto; /* Ensure vertical scrolling if the text is too long */
    box-sizing: border-box; /* Ensure padding and border are considered */
}

/* Ensure the toolbar matches your style (optional) */
.custom-quill-editor .ql-toolbar {
    border-radius: 10px 10px 0 0; /* Match the border radius */
    font-family: "Open Sans", Helvetica;
    font-size: 16px;
    padding: 5px 10px; /* Optional: Adjust padding to match design */
}

/* Customize Quill placeholder style */
.custom-quill-editor .ql-editor::before {
    color: #AAAAAA; /* Match placeholder color */
    font-family: "Open Sans", Helvetica; /* Match font-family */
    font-size: 13px; /* Match font-size */
    font-weight: 400; /* Match font-weight */
    font-style: normal; /* Remove italic style */
    opacity: 1; /* Ensure the placeholder is visible */
}
.rectangle::placeholder {
    font-family: "Open Sans", Helvetica;
    font-size: 13px;
    font-weight: 400;
    color: #AAAAAA;
}

.dashboard-upload .upload-navlink {
    left: unset !important;
    top: unset !important;
    width: 197px !important;
}

.file-bar {
    height: auto;
    background-color: #FAF9FF;
    border-radius: 5px;
    position: relative;
    width: 100%;
    padding: 7px 19px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.upload-text-wrapper {
    color: #2a2a2a;
    font-size: 14px;
    font-weight: 500;
    left: 14px;
    letter-spacing: 0;
    text-decoration: underline;
    top: 10px;
    white-space: nowrap;
}

.check {
    height: 24px;
    top: 7px;
    width: 24px;
}

.dashboard-upload .frame-10 {
    align-items: flex-start;
    display: flex;
    justify-content: space-between; /* This can be changed to 'center' if only one item needs to be centered */
    margin-top: 3vh;
    width: 100%;
    gap: 20px;
}

button.dashboard-upload-button {
    all: unset;
    box-sizing: border-box;
    cursor: pointer;
    background-color: #6750a4;
    border: 2px solid #6750A4;
    border-radius: 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    min-width: 200px;
    padding: 10px 40px;
    display: inline-flex;
    position: relative;
    width: 100%; /* Make the button take up 100% of the width */
    max-width: 100%; /* Ensure the button doesn't exceed the parent width */
    flex-grow: 1; /* Allow the button to grow and fill the available space */
}

button.dashboard-upload-button:hover{
    background-color: #381e72;
    border: 2px solid #381e72;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    cursor: pointer;
 }

button.dashboard-upload-button:disabled{
   background-color: #c7c7c7;
   border: 2px solid #c7c7c7;
   cursor: not-allowed;
   box-shadow: none;
}


.dashboard-upload .company-container {
    align-self: stretch;
    border: 1px solid;
    border-color: #c7c7c7;
    border-radius: 10px;
    height: auto;
    position: relative;
    width: 100%;
    padding: 10px;
    font-family: "Open Sans", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 17.7px;
}

.company-container::placeholder {
    font-family: "Open Sans", Helvetica;
    font-size: 13px;
    font-weight: 400;
    color: #AAAAAA;
}

.company-caption {
    font-family: "Open Sans", Helvetica;
    font-size: 13px;
    font-weight: 400;
    color: #AAAAAA;
    line-height: 1.4;
}


.option-button {
    all: unset;
    align-items: center;
    background-color: #E5E5EA;
    border-radius: 10px;
    box-sizing: border-box;
    display: inline-flex;
    gap: 10px;
    justify-content: center;
    padding: 10px 50px;
    position: relative;
    cursor: pointer;
    min-width: 40%;
    color: #ffffff;
    font-family: "Open Sans", Helvetica;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.option-button:hover {
    background-color: #9A82DB;
}

.option-button.selected {
    background-color: #6750a4;
}
.sufficient-balance{
    text-align: center;
    font-weight: 600;
    color: #6750a4;
}

@media screen and (max-width: 1024px) {
    .dashboard-return-button {
        margin-top: 10vh;
        margin-left: 2vh;
    }
}

/* Add at the end of your CSS file */
@keyframes slideIn {
    from {
        opacity: 0;
        transform: translate(-50%, 100%);
    }
    to {
        opacity: 1;
        transform: translate(-50%, 0);
    }
  }
  
  .mobile-warning {
    animation: slideIn 0.3s ease-out;
  }
  
  /* Hide warning on larger screens */
  @media screen and (min-width: 769px) {
    .mobile-warning {
      display: none;
    }
}