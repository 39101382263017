body {
    font-family: Arial, sans-serif;
  }
  
  .pdf-generator {
    display: flex;
    flex-direction: column; /* Stack sections vertically */
  }
  
  .pdf-generator-main-content {
    display: flex;
    flex: 1;
  }
  
  .preview-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #F9FBFC;
  }
  
  .preview-iframe {
    flex: 0 0 100vh; /* Fixed height for the PDF iframe */
    border: 1px solid #ccc;
    border-radius: 8px;
  }
    
  .templates-section {
    flex: 1;
    padding: 20px;
    border-left: 1px solid #ccc;
    background-color: white;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    /* Increase padding to accommodate both button and message */
    padding-bottom: 120px; /* Increased from 80px to make room for the message */
}
  
  .templates-header {
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: 600;
  }
  
.template-list {
    flex: 1;
    margin-bottom: 20px;
}
  
  .template-thumbnail {
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 8px;
    text-align: center;
    cursor: pointer;
    margin-bottom: 10px;
    transition: border 0.3s ease; /* Smooth transition for the border */
  }
  
  .template-thumbnail img {
    width: 50%; /* Adjust width to fill the container */
    border-radius: 4px;
  }
  
  .template-thumbnail p {
    margin-top: 10px;
    font-size: 14px;
  }
  
  .template-thumbnail.selected {
    border: 2px solid #6750A4; /* Purple border for the selected template */
  }
  
  .pdf-generator-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: sticky;
    bottom: 0; /* Stick to the bottom of the container */
    background-color: white; /* Match background to avoid overlap issues */
    padding: 10px 0; /* Add some padding for visual separation */
  }
  
  .download-button-save {
    width:100%;
    padding: 12px 35px;
    font-size: 16px;
    font-weight: 600;
    background-color: #ffffff;
    color: #6750A4;
    border: 2px solid #6750a4;
    border-radius: 10px;
    cursor: pointer;
    margin-bottom: 15px; /* Space between buttons */
  }

  .download-button-save:hover {
    color: #9A82DB;
    border: 2px solid #9A82DB;
    cursor: pointer;
  }

  .download-button-export {
    width:100%;
    padding: 12px 35px;
    font-size: 16px;
    font-weight: 600;
    background-color: #6750a4;
    color: #ffffff;
    border: 2px solid #6750a4;
    border-radius: 10px;
    cursor: pointer;
    margin-bottom: 20px; /* Space between buttons */
  }

  .download-button-export:hover {
    background-color: #9A82DB;
    color: #ffffff;
    border: 2px solid #9A82DB;
  }
  
  /* New styles for the back button */
  .pdf-preview-back-to-edit {
    display: flex;
    justify-content: space-between; /* Space between the two main elements */
    align-items: center; /* Vertically center the content */
    height: auto; /* Fit content naturally */
    margin-bottom: 20px;
  }

  .back-to-edit {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .preview-export-img {
    width: 25px;
    height: 25px;
    margin-right: 3px;
  }
  
  .preview-export-text {
    font-size: 15px;
    font-weight: 600;
  }

  .bold-keywords-checkbox {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: 600; /* Set font weight to 600 */
    font-size: 16px;  /* Set font size to 16px */
  }
  
  .bold-keywords-checkbox input {
    margin-right: 8px;
    width: 18px; /* Adjust checkbox size if needed */
    height: 18px;
    accent-color: #6750A4; /* Set the background color of the checkbox */
    cursor: pointer;
  }
  
  .bold-keywords-checkbox input:checked {
    background-color: #6750A4; /* Background color for the checked state */
  }
  
  .bold-keywords-checkbox input:checked::before {
    content: '';
    color: white;
  }
  
  .save-application-modal-content {
    background-color: #fff;
    border-radius: 5px;
    flex-direction: column;
    display: flex;
    width: 100%;
    height: 100%; /* Ensure it takes the full height of the modal */
    justify-content: space-between; /* Ensure space between content and buttons */
    padding: 10px; /* Add padding to the modal content */
  }

  .save-application-modal-group {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }

  .save-application-resume-modal-profile {
    background-color: #fff;
    border-radius: 16px;
    justify-content: center;
    align-items: center;
    width: 40%;
    padding: 20px;
    display: flex;
    position: fixed;
    top: 28%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    z-index: 200;
}

.save-application-modal-button-container {
    display: flex;
    justify-content: flex-end; /* Align the buttons to the right */
    gap: 20px; /* Add some space between the buttons */
    margin-top: 20px; /* Push the button container to the bottom */
}

.save-resume-application-button-save {
    padding: 10px 20px; /* Add padding for better button size */
    width: 150px;
    color: #ffffff;
    cursor: pointer;
    background-color: #6750A4;
    border: 1px solid #6750A4;
    border-radius: 8px;
    font-weight: 600;;
}

.save-resume-application-button-save:hover {
    background-color: #381E72;
}

.save-resume-application-button-save:disabled {
    cursor: not-allowed;
    box-shadow: none;
    background-color: #c7c7c7;
    border: 2px solid #c7c7c7;
}

.save-resume-application-button-cancel {
    padding: 10px 20px; /* Add padding for better button size */
    width: 150px;
    color: #AAAAAA;
    background-color: #ffffff;
    cursor: pointer;
    border-radius: 8px;
    border: 1px solid #AAAAAA;
    font-weight: 600;
}

.toolbar {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .toolbar button {
    width: 30px;
    height: 30px;
    font-size: 18px;
  }
  
  .toolbar span {
    margin: 0 10px;
    font-size: 16px;
  }

  /* Tablet Styles */
@media (max-width: 1024px) {
  .pdf-generator-main-content {
      padding: 15px;
  }

  .preview-section {
      padding: 15px;
  }

  .pdf-viewer-container {
      min-height: 600px;
      padding: 15px;
  }

  .template-toolbar {
      gap: 10px;
  }

  .back-to-edit {
      padding: 6px 12px;
  }

  .preview-export-img {
      width: 20px;
      height: 20px;
  }

  .preview-export-text {
      font-size: 14px;
  }
}

/* Mobile Styles */
@media (max-width: 768px) {
  .save-application-resume-modal-profile {
    width: 90%;
  }

  .pdf-generator-main-content {
      padding: 10px;
  }

  .preview-section {
      padding: 10px;
  }

  .pdf-preview-back-to-edit {
      flex-direction: column;
      align-items: stretch;
  }

  .template-toolbar {
      flex-direction: column;
      align-items: stretch;
  }

  .back-to-edit {
      justify-content: center;
      width: 100%;
      padding: 12px;
  }

  .pdf-viewer-container {
      min-height: 500px;
      padding: 10px;
  }

  .zoom-controls {
      position: sticky;
      top: 0;
      z-index: 10;
      background-color: rgba(255, 255, 255, 0.95);
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  /* Make zoom controls more touch-friendly */
  .zoom-controls button {
      padding: 12px 16px;
      min-width: 44px;
      min-height: 44px;
  }

  .feedback-form {
      height: 300px;
  }
}

/* Small Mobile Styles */
@media (max-width: 360px) {
  .pdf-generator-main-content,
  .preview-section {
      padding: 8px;
  }

  .pdf-viewer-container {
      min-height: 400px;
      padding: 8px;
  }

  .preview-export-text {
      font-size: 13px;
  }
}