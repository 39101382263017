/* ----------------------Mobile View----------------------*/
.logo-nmv {
  display: block;
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.navigation-buttons-nmv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto;
  gap: 40px;
}
.empty-column {
  width: 10px;
  visibility: hidden;
}
.login-button-nmv {
  width: 77px;
  height: 35px;
  border-radius: 24px;
  border: 2px solid #6750a4;
  background-color: #6750a4;
  color: #ffffff;
  padding: 8px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;
}
/* ----------------------Regular View----------------------*/

.navigation {
  align-items: center;
  background-color: #ffffff;
  /* box-shadow: 0px 4px 4px #0000001a; */
  border-bottom: 1px solid #eae5ea; /* Add bottom line */
  display: flex;
  justify-content: space-between;
  padding: 12px 5%;
  position: relative;
  width: 100%;
}
.navigation-2202 {
  padding-left: 8.5%;
  padding-right: 8.5%;
  left: 0 !important;
  position: fixed !important;
  top: 0 !important;
  z-index: 99999999;
}
.navigation .futuristic-modern {
  height: 30px;
  position: relative;
  width: 200px;
}

.button-primary {
  /* all: unset; */
  border: 2px #6750a4 solid;
  background-color: #6750a4;
  border-radius: 5px;
  box-sizing: border-box;
  height: 36px;
  position: relative;
  width: 160px;
  cursor: pointer;
  text-align: center;
}

.button-no-border {
  /* all: unset; */
  border: none;
  background-color: #ffffff;
  outline: none;
  height: 36px;
  position: relative;
  width: 160px;
  cursor: pointer;
  text-align: center;
}

.button-primary-text {
  color: #ffffff;
  font-family: "Open Sans", Helvetica;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
}

.button-primary:hover {
  /* all: unset; */
  background-color: #9a82db;
  border: 2px #9a82db solid;
}

.button-primary:hover .button-primary-text {
  color: #faf9ff;
}

.button-secondary-text {
  color: #7a7a7a;
  font-family: "Open Sans", Helvetica;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
}

.button-secondary-text:hover {
  color: #6750a4;
  font-weight: 700;
}

.button-secondary-text .navigation .button-discord {
  all: unset;
  cursor: pointer;
}

.button-discord:hover {
  opacity: 50%;
}

.navigation .button-discord .discord-icon {
  width: 28px;
  height: 28px;
}

.navigation-buttons {
  display: flex;
  align-items: center;
  gap: 40px;
}
