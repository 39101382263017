body {
    font-family: 'Open Sans', sans-serif !important;
    background-color: white;
    padding-bottom: 50px; /* Add padding at the bottom of the page */
  }

  .Privacy_policy{
    margin-bottom: 50px;
  }
  
  .policy-get-started-header{
    align-items: center;
    display: flex;
    margin-top: 40px;
    justify-content: center;
    color: #2a2a2a;
    font-family: "Open Sans";
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 30px;
  }


  .document_text {
    font-family: "Open Sans";
    margin-left: 80px;
    margin-right: 80px;
    font-size: 20px;
    line-height: 1.6;
    margin-bottom: 30px;
  }

  .document_title {
    font-family: "Open Sans";
    margin-left: 80px;
    margin-right: 80px;
    font-size: 20px;
    line-height: 1.6;
    font-weight: bold;
    margin-top: 20px;
  }

  .policy-flex-container {
    display: block;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 100px;
  }
  
 
  