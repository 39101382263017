.popup-text-holder {
    background-color: #faf9ff;
    border: 1px solid #e5e5ea;
    border-radius: 10px;
    padding-left: 10px;
    padding-top: 15px;
    line-height: 1.6;
}

/* Ensure bullets are displayed for unordered lists */
.popup-text-holder ul {
    list-style-type: disc; /* Display bullet points */
    margin-left: 20px; /* Indent the bullet points */
    padding-left: 0; /* Remove any default padding */
}

/* Style for nested unordered lists */
.popup-text-holder ul ul {
    list-style-type: circle; /* Display hollow circle for nested bullet points */
    margin-left: 20px; /* Indent nested list further */
}

/* Further nested unordered lists */
.popup-text-holder ul ul ul {
    list-style-type: square; /* Display square bullet for deeper levels */
    margin-left: 20px; /* Indent further */
}

/* Style for ordered lists */
.popup-text-holder ol {
    list-style-type: decimal; /* Display numbers for ordered lists */
    margin-left: 20px; /* Indent the numbers */
    padding-left: 0; /* Remove any default padding */
}

/* Nested ordered lists */
.popup-text-holder ol ol {
    list-style-type: lower-alpha; /* Use letters for nested ordered lists */
    margin-left: 20px; /* Indent nested ordered list */
}

/* Style for list items */
.popup-text-holder li {
    list-style-type: disc;
    margin-bottom: 10px; /* Add space between list items */
    margin-left: 20px;
    padding-left: 0; /* Remove extra padding */
}

.popup-text-holder li li {
    list-style-type: circle;
    margin-bottom: 10px; /* Add space between list items */
    margin-left: 40px;
    padding-left: 0; /* Remove extra padding */
}

/* Adjust indentation for Quill's custom indent classes */
.popup-text-holder li.ql-indent-1 {
    margin-left: 40px; /* Indent level 1 */
}

.popup-text-holder li.ql-indent-2 {
    margin-left: 60px; /* Indent level 2 */
}

.popup-text-holder li.ql-indent-3 {
    margin-left: 80px; /* Indent level 3 */
}

/* You can extend this further for deeper indentation levels */
.popup-text-holder li.ql-indent-4 {
    margin-left: 100px; /* Indent level 4 */
}

.job-description-modal-header {
    display: flex;
    justify-content: space-between; /* Aligns the title to the left and the icon to the right */
    align-items: center; /* Vertically centers the icon with the title */
    padding: 10px 0;
    margin-bottom: 30px;
}

.job-description-modal-title {
    font-size: 20px;
    font-weight: 600;
}

.close-icon {
    cursor: pointer; /* Makes the icon look clickable */
    width: 24px;
    height: 24px;
}