/* Base styles */
body {
    font-family: "Open Sans", Helvetica !important;
    margin: 0;
    padding: 0;
}

.ClResults {
    background-color: #FFFFFF;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.ClResults-main {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin: 0 8%;
    padding: 25px 9%;
    min-height: 100vh;
    box-sizing: border-box;
}

.title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
}

.title-2 {
    color: #000000;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 1.4;
    margin: 0;
    position: relative;
}

.resumeContent {
    background-color: #FAF9FF;
    width: 100%;
    padding: 4% 8%;
    min-height: 50vh;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
    box-sizing: border-box;
    line-height: 1.6;
}

/* Form elements */
.experience-form-input {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 20px;
    font-size: 16px;
    box-sizing: border-box;
}

.form-label {
    display: block;
    margin-bottom: 8px;
    font-weight: 600;
}

.coverletter-textarea {
    width: 100%;
    height: 500px;
    font-size: 16px;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
    line-height: 1.5;
    box-sizing: border-box;
}

/* Utility classes */
.img-box {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 10px 0;
}

.copy-img {
    height: 24px;
    width: 24px;
    cursor: pointer;
    transition: opacity 0.2s;
}

.copy-img:hover {
    opacity: 0.8;
}

.credits-used-row {
    display: flex;
    justify-content: flex-end;
}

.credits-used {
    color: #1f1f1f;
    font-size: 14px;
    font-weight: 700;
    padding: 4px 10px;
    border: solid 2px #1f1f1f;
    border-radius: 8px;
}

.export-button {
    min-width: 165px;
    padding: 12px 24px;
}

/* Tablet styles (768px - 1024px) */
@media (max-width: 1024px) {
    .ClResults-main {
        margin: 0 5%;
        padding: 20px 5%;
        gap: 20px;
    }

    .title-2 {
        font-size: 28px;
        line-height: 1.3;
    }

    .resumeContent {
        padding: 5% 6%;
    }

    .export-button {
        min-width: 140px;
        padding: 10px 20px;
    }
}

/* Mobile styles (max-width: 767px) */
@media (max-width: 767px) {
    .ClResults-main {
        margin: 0;
        padding: 16px;
        gap: 16px;
    }

    .title {
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
    }

    .title-2 {
        font-size: 24px;
        line-height: 1.2;
        white-space: normal;
    }

    .resumeContent {
        padding: 20px;
        line-height: 1.4;
    }

    .coverletter-textarea {
        height: 400px;
        font-size: 14px;
        padding: 12px;
    }

    .experience-form-input {
        padding: 10px;
        font-size: 14px;
        margin-bottom: 16px;
    }

    .form-label {
        font-size: 14px;
    }

    .export-button {
        width: 100%;
        min-width: unset;
    }

    .credits-used {
        font-size: 12px;
        padding: 3px 8px;
    }

    .copy-img {
        height: 20px;
        width: 20px;
    }
}

/* Small mobile styles (max-width: 360px) */
@media (max-width: 360px) {
    .ClResults-main {
        padding: 12px;
    }

    .title-2 {
        font-size: 22px;
    }

    .resumeContent {
        padding: 16px;
    }
}