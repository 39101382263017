.button {
  all: unset;
  align-items: center;
  background-color: #6750a4;
  border-radius: 40px;
  box-sizing: border-box;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  padding: 10px 40px;
  position: relative;
  cursor: pointer;
  min-width: 200px;
  border: 2px solid transparent;
}

.button:hover:enabled {
  background-color: #ffffff;
  border-color: black;
  
}
.button .text {
  color: #ffffff;
  font-family: "Open Sans", Helvetica;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.button:hover:enabled .text{
  color: black;
}

button:disabled {
  cursor: not-allowed; 
  transition: none;
  box-shadow:none;
}


.white-arrow {
  margin-left: 5px;
  height: 20px;
  width: 20px;
  vertical-align: middle;
  transition: content 0.3s;
}
.button:hover:enabled .white-arrow {
  content: url("/static/img/arrow-right-black.png");
 
}

/* -------------------------------------------------------------- */

/* .button-secondary {
  border: 2px #6750A4 solid;
  background-color: #ffffff;
}

.button .button-secondary-text {
  color: #6750A4;
  font-family: "Open Sans", Helvetica;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: fit-content;

}

.button-secondary:hover {
 
  background-color: #9A82DB;
  border: 2px #9A82DB solid;
}

.button-secondary:hover .button-secondary-text {
  color: #FAF9FF;
}

*/