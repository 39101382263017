.detail-info {
    --content-padding: 24px;
    --section-gap: 24px;
    background: white;
    border-radius: 8px;
    padding: var(--content-padding);
    margin-bottom: 20px;
    border: 1px solid #ddd;
    transition: border-color 0.2s ease;
}

.detail-info.missing-info {
    border: 2px solid #DC2626; /* Red border for missing info state */
    border-radius: 8px; /* Optional: rounded corners */
    box-shadow: 0 0 0 1px rgba(220, 38, 38, 0.1); /* Optional: subtle glow effect */
}

.detail-info-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.detail-info-title {
    display: flex;
    align-items: center;
    gap: 12px;
}

.detail-info-match-score {
    background: #e8f5e9;
    padding: 4px 8px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
}

.detail-info-score {
    background: #4caf50;
    color: white;
    padding: 2px 6px;
    border-radius: 4px;
}

.detail-info-delete-btn {
    color: #f44336;
    background: none;
    border: none;
    cursor: pointer;
}

.detail-info-form-row {
    display: flex;
    gap: 20px;
    margin-bottom: 15px;
}

.detail-info-form-group {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.detail-info-form-group input {
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 8px;
}

.detail-info-description-section {
    display: flex;
    gap: 24px;
    width: 100%;
    min-width: 0; /* 防止flex子项超出 */
}

.detail-info-description-column {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-width: 0; /* 添加这行，防止flex子项超出 */
    width: 50%;   /* 确保两列等宽 */
}

.detail-info-revised-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.detailinfo-img{
    width: 20px;
}

.tooltip-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.tooltip {
    visibility: hidden;
    position: absolute;
    background-color: #333;
    color: white;
    text-align: center;
    padding: 6px 12px;
    min-width: 150px;
    border-radius: 4px;
    font-size: 11px;
    white-space: nowrap;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 5px;
    opacity: 0;
    transition: opacity 0.2s, background-color 0.3s;
}

/* Show the tooltip on hover */
.tooltip-container:hover .tooltip {
    visibility: visible;
    opacity: 1;
}

/* Animation for copied state */
.show-copied {
    visibility: visible !important;
    opacity: 1 !important;
    background-color: #4CAF50 !important; /* Green color for success */
    animation: bounce 0.5s ease;
}

@keyframes bounce {
    0%, 100% { transform: translateX(-50%) translateY(0); }
    50% { transform: translateX(-50%) translateY(-5px); }
}

.tooltip::before {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #333 transparent;
}

/* Update arrow color for copied state */
.show-copied::before {
    border-color: transparent transparent #4CAF50 transparent;
}

.detail-info-ai-tune-btn {
    background: #F0EDFF;
    border: none;
    font-size: 14px;
    border-radius: 8px;
    padding: 10px 12px;
    cursor: pointer;
    display: flex;
    align-items: center;    /* Center vertically */
    justify-content: center; /* Center horizontally */
    gap: 8px;              /* Consistent spacing between text and icon */
}

.detail-info-ai-tune-btn img {
    display: block;
    width: 16px;
    height: 16px;
}

.detail-info-description-box {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.detail-info-description-input,
.detail-info-description-input-revise {
    width: 100%;
    min-height: 100px;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 8px;
    resize: vertical;
    margin-top: 5px;
    margin-bottom: 5px;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
    color: #2A2A2A;
}

.detail-info-description-text-block-container {
    position: relative;
    width: 100%;
    min-width: 0; /* 防止内容超出 */
}

.detail-info-description-action-buttons-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    gap: 20px;
    background-color: #F5F5F5;
    padding: 8px;
    border-radius: 20px;
    z-index: 1;
    padding-left: 15px;
    padding-right: 15px;
    cursor: pointer;
}

.detail_info_description_edit,
.detail_info_description_delete {
    position: relative;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.detail_info_description_edit_normal_img,
.detail_info_description_edit_hover_img,
.detail_info_description_delete_normal_img,
.detail_info_description_delete_hover_img {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    transition: opacity 0.2s;
}

.detail-info-description-column h3 {
    margin: 0;
    padding: 12px 0;
    height: 40px;
    display: flex;
    align-items: center;
}

.detail-info-description-revise-modify {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
}

.detail-info-button-wrapper {
    position: relative;
    flex: 1; /* This makes both wrappers take equal space */
    display: flex; /* This ensures the button inside takes full width */
}

.detail-info-add-new-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    width: 100%; /* Make button take full width of wrapper */
    padding: 8px;
    background: none;
    border: 1px solid #E5E5EA;
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
    color: #7A7A7A;
    font-weight: 600;
    font-size: 14px;
}

.detail-info-add-new-btn:hover {
    border: 1px solid #6750A4;
    color: #6750A4;
}

/* AI Suggestions Dropdown Container */
.ai-suggestions-dropdown {
    position: absolute;
    bottom: 100%;
    right: 0;
    width: 300px;
    min-height: 300px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    margin-bottom: 8px;
    border: 1px solid #E5E7EB;
    z-index: 1000;
}

.ai-suggestions-header {
    padding: 12px 16px;
    border-bottom: 1px solid #E5E7EB;
    font-weight: 600;
}

.ai-suggestions-body {
    padding: 12px 16px;
    overflow-y: auto;
    border-bottom: 1px solid #E5E7EB;
}

.ai-suggestion-item {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    margin-bottom: 8px;
    cursor: pointer;
    padding: 8px;
    border: 1px solid #E5E5EA;
    border-radius: 8px;
    transition: all 0.2s ease;
}

.ai-suggestion-item:hover {
    background-color: #F8F5FF;
    border-color: #2A2A2A;
}

.ai-suggestion-item.selected {
    background-color: #F8F5FF;
}

.ai-suggestion-checkbox {
    margin-top: 4px;
    cursor: pointer;
    width: 16px;
    height: 16px;
}

.ai-suggestion-text {
    font-size: 14px;
    line-height: 1.5;
    color: #4B5563;
}

.ai-suggestions-footer {
    padding: 12px 16px;
    display: flex;
    justify-content: flex-end;
}

.ai-suggestions-add-button {
    background-color: #381E72;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 6px 16px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
}

.ai-suggestion-checkbox {
    width: 16px;
    height: 16px;
    margin-top: 4px;
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid #E5E7EB;
    border-radius: 4px;
    position: relative;
    transition: all 0.2s ease;
    min-width: 16px; /* Add this */
    min-height: 16px; /* Add this */
}

.ai-suggestion-checkbox:checked {
    background-color: #6750A4;
    border-color: #6750A4;
}

.ai-suggestion-checkbox:checked::after {
    content: '';
    position: absolute;
    left: 4px;
    top: 1px;
    width: 6px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.ai-suggestion-checkbox:hover {
    border-color: #6750A4;
}

.highlight-keyword {
    background-color: #DFD9FA;
    padding: 0 2px;
    border-radius: 2px;
    transition: background-color 0.2s;
}

.keyword-indicator {
    font-size: 14px;
    color: #2a2a2a;
    margin-left: 8px;
    padding: 6px 8px;
    background-color: #F0EDFF;
    border-radius: 4px;
}

@media (max-width: 1024px) {
    .detail-info {
        --content-padding: 16px;
        --section-gap: 16px;
    }

    .detail-info-form-row {
        flex-direction: column;
        gap: 12px;
    }

    .detail-info-description-section {
        flex-direction: column;
        gap: var(--section-gap);
    }

    .detail-info-description-column {
        width: 100%;
    }

    .detail-info-description-input,
    .detail-info-description-input-revise {
        min-height: 80px;
    }

    .ai-suggestions-dropdown {
        width: 280px;
    }
}

/* Mobile styles */
@media (max-width: 768px) {
    .detail-info {
        --content-padding: 12px;
        --section-gap: 12px;
    }

    .detail-info-header {
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
    }

    .detail-info-title {
        flex-wrap: wrap;
        gap: 8px;
        width: 100%;
    }

    .delete-button {
        margin-top: 8px;
    }

    .detail-info-match-score {
        width: 100%;
        justify-content: space-between;
    }

    .detail-info-revised-header {
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
    }

    .detail-info-ai-tune-btn {
        width: 100%;
    }

    /* Adjust description action buttons for mobile */
    .detail-info-description-action-buttons-container {
        position: static;
        transform: none;
        margin-top: 8px;
        justify-content: center;
        width: 100%;
    }

    .detail-info-description-text-block-container {
        padding-bottom: 40px;
    }

    .ai-suggestions-dropdown {
        position: fixed;
        top: auto;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        max-height: 70vh;
        margin: 0;
        border-radius: 12px 12px 0 0;
        border-bottom: none;
        animation: slideUp 0.3s ease-out;
    }

    .ai-suggestions-header {
        padding: 16px;
        text-align: center;
        position: relative;
    }

    .ai-suggestions-header::after {
        content: '';
        position: absolute;
        top: 8px;
        left: 50%;
        transform: translateX(-50%);
        width: 40px;
        height: 4px;
        background-color: #E5E7EB;
        border-radius: 2px;
    }

    .ai-suggestions-body {
        padding: 16px;
    }

    .ai-suggestion-item {
        padding: 12px;
        margin-bottom: 12px;
    }

    .ai-suggestion-checkbox {
        width: 24px;
        height: 24px;
        min-width: 24px;
    }

    .ai-suggestions-footer {
        padding: 16px;
        box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.05);
    }

    .ai-suggestions-add-button {
        width: 100%;
        padding: 12px;
        font-size: 16px;
        border-radius: 8px;
    }
}

@media (max-width: 360px) {
    .ai-suggestions-dropdown {
        max-height: 80vh;
    }

    .ai-suggestion-text {
        font-size: 13px;
    }
}

/* Touch device optimizations */
@media (hover: none) {
    .detail-info-description-action-buttons-container {
        opacity: 1;
        visibility: visible;
    }

    .detail-info-add-new-btn {
        padding: 12px;  /* Larger touch target */
    }

    .ai-suggestion-item {
        padding: 12px;
    }

    .ai-suggestion-checkbox {
        width: 20px;
        height: 20px;
    }

    /* Hide tooltips on touch devices */
    .tooltip {
        display: none;
    }
}