
.reviews-container {
    width: 90%;
    margin: 0 auto;
    overflow: auto;
    position: relative;
    height: auto;
   
    display: flex;  
    justify-content: center; 
    align-items: center;
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
        
}

.reviews-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;  
    align-items: center;
    
        
}

.review {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-radius: 20px;
    margin: 0 10px;
    min-width: 300px;
    box-sizing: border-box;
}  
