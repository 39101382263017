
/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap'); */
.review {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 10px;
    border-radius: 20px;
    background-color: #FAF9FF;
    min-width: 329px;
    min-height: 170px;
}

.stars {
    font-size: 20px;
    margin-bottom: 10px;
}

.comment {
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    color:#000000;
}