*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  justify-content: center;
  align-items: center;
}
html {
  height: 0 !important;
}

.frame {
  align-items: center;
  flex-direction: column;
  gap: 150px;
  padding: 0px 8.5% 140px;
  width: 100%;
}
.announcement-banner {
  margin-top: 62px;
  display: flex;
  background-color: #6750a4;
  padding: 15px;
  align-items: center;
  justify-content: center;
}
.banner-text {
  color: #ffffff;
  font-family: "Open Sans";
  font-weight: 500;
}

.section-1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 130px auto;
  width: 95%;
  max-width: 1250px;
  box-sizing: border-box;
  padding: 0 20px;
  gap: 20px;
}

.section-stepper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 60px auto;
  box-sizing: border-box;
  padding: 0 20px;
  gap: 10px;
}

.section-3,
.section-4,
.section-5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 140px auto;
  width: 90%;
  box-sizing: border-box;
  padding: 0 20px;
  gap: 100px;
}

.section-34 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 140px auto;
  width: 100%;
  box-sizing: border-box;
  padding: 80px 20px;
  gap: 100px;
  background-color: #faf9ff;
}

.title-part {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
}

.features-title,
.ai-chatbot-intro,
.keys-title,
.cover-text,
.resume-opti,
.section-6 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.title-part .frame-2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
  text-align: center;
}

.title-part .frame-2 h1 {
  color: #101010;
  font-family: "Open Sans", Helvetica;
  font-size: 49px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 1.1;
  text-align: start;
}

.home-new-title-highlight {
  background-color: #FFE975; /* Yellow highlight color */
  padding: 0 8px;
  border-radius: 4px;
}

.title-part .frame-2 p {
  color: #7a7a7a;
  font-family: "Open Sans", Helvetica;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  max-width: 700px;
  text-align: start;
}

.features-title h2,
.ai-chatbot-intro h2,
.keys-title h2,
.cover-text h2,
.resume-opti h2 {
  color: #101010;
  font-family: "Open Sans", Helvetica;
  font-size: 52px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
}

.features-title p,
.ai-chatbot-intro p,
.keys-title p,
.keys-1 p,
.keys-2 p,
.cover-text p,
.resume-opti p {
  color: #2a2a2a;
  font-family: "Open Sans", Helvetica;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  max-width: 700px;
  text-align: center;
}
.ribbon {
  position: relative;
}
.ribbon::after {
  content: "";
  position: absolute;
  bottom: 0.1em;
  left: 0;
  width: 100%;
  height: calc(0.32em + 1px);
  background-color: #f0edff;
  z-index: -1;
}
.button-instance {
  flex: 0 0 auto !important;
  left: unset !important;
  top: unset !important;
}
.gif-container-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.gif-container-2 img {
  max-width: 88%;
  max-height: 70%;
  box-shadow: rgba(149, 157, 165, 0.1) 0px 4px 10px;
  object-fit: contain;
  border-radius: 8px;
}

/* ---------------------------------Home Metrics----------------------- */
hr.home-header-line {
  border: 1px solid #e5e5ea;
  width: 100%;
  margin: 0px;
}

.section-1 .frame-5 {
  display: flex;
  justify-content: space-between;
  gap: 60px;
}

.home-metrics {
  display: flex;
  flex-direction: column;
  align-items: start;
}

h4.home-metric-number {
  font-size: 40px;
  margin-bottom: 5px;
  font-weight: 700;
}

p.home-metric-comment {
  font-size: 16px;
  font-weight: 600;
}

/* ---------------------------------Section-2----------------------- */
.section-2 {
  display: flex;
  margin: 100px auto;
  box-sizing: border-box;
  padding: 30px 20px;
  background-color: #faf9ff;
}

.testimonials {
  display: flex;
  justify-content: space-between;
  max-width: 1250px;
  margin: 30px auto;
}

.testimonial-1,
.testimonial-2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  margin: 10px;
  padding: 30px;
  /* max-width: 45%; */
  background-color: #ffffff;
  border-radius: 8px;
}

.testimonial-user-1,
.testimonial-user-2 {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.testmonial-user-img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.user-1-name,
.user-2-name {
  font-size: 1.2em;
  font-weight: bold;
}

.testimonial-content-1 {
  font-size: 1em;
  line-height: 1.5;
  color: #333;
}

/* ---------------------------------Features----------------------- */
.section-3 {
  gap: 20px;
}
.features-title h2 {
  font-size: 40px;
  max-width: 800px;
}
.features-button {
  font-family: "Open Sans", Helvetica;
  color: #6750a4;
  font-size: 14px;
  font-weight: bold;
  border: 2px solid #6750a4;
  border-radius: 40px;
  text-align: center;
  padding: 10px 40px;
  background-color: transparent;
  cursor: pointer;
  display: inline-block;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.features-button:hover {
  background-color: #6750a4;
  color: white;
}

/* ---------------------------------Chatbot----------------------- */
.section-34 {
  gap: 30px;
}

.ai-chatbot-intro {
  background-image: url("/static/img/Home_pic1.png");
  background-size: contain;
  background-position: center 60px;
  background-repeat: no-repeat;
}

.ai-chatbot-intro h2 {
  font-size: 40px;
  max-width: 800px;
}

.ai-chatbot-img img {
  max-width: 30%;
  margin: auto;
}

/* ---------------------------------Keys----------------------- */

.section-4 {
  gap: 20px;
}

.keys-img img {
  max-width: 60%;
  margin: auto;
}

.keys {
  display: flex;
  justify-content: space-between;
  max-width: 1080px;
}
.keys-1,
.keys-2 {
  flex: 1;
  margin: 10px;
  padding: 20px;
  max-width: 40%;
}
.keys-title h2 {
  font-size: 40px;
  max-width: 800px;
}
.keys-1 h3,
.keys-2 h3 {
  font-family: "Open Sans", Helvetica;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 600;
}
.keys-1 p,
.keys-2 p {
  font-weight: 400;
}
.keys img {
  max-width: 24px;
  margin-bottom: 5px;
}

/* ---------------------------------section-5----------------------- */

.section-5 {
  /* background-image: url('/static/img/Home_background_shape.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; */
  width: 100%;
  padding: 20px 20px;
  background-color: #faf9ff;
}

.cover-letter,
.resume-contain {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  gap: 20px;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
}
.cover-letter {
  margin-top: 80px;
  max-width: 1080px;
}
.cover-text {
  flex: 1;
  text-align: left;
  display: flex;
  align-items: flex-start;
}
.cover-text h2,
.resume-opti h2 {
  font-size: 40px;
}
.cover-text p,
.cover-text button {
  text-align: left;
}
.arrow {
  background-color: transparent;
  border: none;
  font-family: "Open Sans", Helvetica;
  font-size: 18px;
  color: #6750a4;
  font-weight: 600;
  cursor: pointer;
  display: inline-block;
  padding-right: 15px;
  text-decoration: none;
  white-space: nowrap;
}

.arrow::after {
  content: "";
  background-image: url("/static/img/arrow-right-purple.png");
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-left: 5px;
}
.cover-gif,
.resume-gif {
  flex: 1;
  max-width: 100%;
  overflow: hidden;
}

.cover-gif img,
.resume-gif img {
  width: 100%;
  height: auto;
  display: block;
}
.resume-contain {
  margin-bottom: 80px;
  max-width: 1080px;
}
.resume-opti {
  flex: 1;
  text-align: right;
  display: flex;
  align-items: flex-end;
}
.resume-opti p,
.resume-opti button {
  text-align: right;
}
/* ---------------------------------section-6----------------------- */
.section-6-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.section-6 h2 {
  color: #2a2a2a;
  font-family: "Open Sans", Helvetica;
  font-size: 36px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
}
/* ---------------------------------foot----------------------- */
.foot-box {
  padding-bottom: 60px;
}

@media only screen and (max-width: 480px) {
  .frame-2 h1 {
    font-size: 24px;
  }

  .features-title h2,
  .keys-title h2,
  .cover-text h2,
  .resume-opti h2,
  .section-6 h2 {
    font-size: 22px;
  }

  .frame-2 p,
  .features-title p,
  .keys-title p,
  .cover-text p,
  .resume-opti p {
    font-size: 14px;
  }

  .ribbon::after {
    height: calc(0.2em + 1px);
  }
}

@media only screen and (max-width: 768px) {
  .section-1 {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 5%;
    margin-bottom: 0;
  }

  .frame-2 h1 {
    font-size: 30px;
  }

  .features-title h2,
  .keys-title h2,
  .cover-text h2,
  .resume-opti h2,
  .section-6 h2 {
    font-size: 28px;
  }

  .frame-2 p,
  .features-title p,
  .keys-title p,
  .cover-text p,
  .resume-opti p {
    font-size: 16px;
  }

  .ribbon::after {
    height: calc(0.3em + 1px);
  }
  .keys-1 h3,
  .keys-2 h3 {
    font-size: 16px;
  }
  .keys-1 p,
  .keys-2 p {
    font-size: 16px;
  }
}

/* ---------------------------------Mobile + IPad View Updates ----------------------- */
@media (max-width: 768px) {
  /* For section-1 mobile view */
  .section-1 {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    width: 100%;
  }

  .section-1 .title-part {
    margin: 5%;
  }

  .section-1 .gif-container-2 {
    overflow: hidden;
    width: 100%;
  }

  .section-1 .gif-container-2 img {
    width: 100%;
    height: 311px;
    object-fit: cover;
    object-position: top;
  }

  .section-1 .title-part .frame-2 h1 {
    font-size: 60px;
  }

  .section-1 .title-part .frame-2 p {
    font-size: 20px;
  }

  /* For section-stepper mobile view */
  .section-stepper {
    margin-bottom: 0;
  }

  /* For section-3 mobile view */
  .section-3 {
    margin-top: 0;
  }

  /* For section-34 mobile view */
  .section-34 {
    display: flex; /* Ensure Flexbox behavior */
    flex-direction: column; /* Stack content vertically */
    align-items: flex-start; /* Align items to the left */
    justify-content: flex-start; /* Ensure content aligns to the top */
  }

  .section-34 h2 {
    font-size: 28px;
  }

  .section-34 p {
    font-size: 16px;
  }

  .ai-chatbot-img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: auto;
    padding: 0;
    margin: 0 auto;
  }

  .ai-chatbot-img img {
    width: 100%;
    max-width: 100%;
    height: auto;
    object-fit: contain;
  }

  /* For section-4 mobile view */
  .section-4 h2 {
    font-size: 28px;
  }

  .section-4 p {
    font-size: 16px;
  }

  /* For keys mobile view */
  .keys-img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0 auto;
  }

  .keys-img img {
    width: 100%;
    max-width: 100%;
    height: auto;
    object-fit: contain;
  }

  .section-5 {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 0;
  }

  .cover-letter {
    display: flex;
    flex-direction: column;
    margin-top: 0;
  }

  .resume-contain {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 0;
    margin-top: 0;
  }

  .section-6 {
    padding-top: 40px;
    padding-bottom: 40px;
    text-align: center;
    margin-left: 5%;
    margin-right: 5%;
  }

  .section-6 .h2 {
    font-size: 28px;
  }
}

@media (max-width: 480px) {
  .testimonials {
    flex-direction: column;
    align-items: center;
    width: 100% !important;
  }
  .keys {
    flex-direction: column;
    align-items: center;
    width: 100% !important;
  }
  .keys-1,
  .keys-2 {
    max-width: 85%;
  }
  .section-1 .gif-container-2 img {
    height: 172px;
  }

  .section-1 .title-part .frame-2 h1 {
    font-size: 40px;
  }

  .section-1 .title-part .frame-2 p {
    font-size: 16px;
  }
}
