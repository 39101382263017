.navi-detail-outer {
    position: relative;
    width: 75%;
    height: 100vh;
    padding-top: 64px; /* Fixed space at top */
    box-sizing: border-box;
    background-color: #F9FBFC;
}

.navi-detail-content-wrapper {
    overflow-y: auto;  /* Move the overflow to the wrapper */
    height: 100%;     /* Take full height of parent minus padding */
    flex-direction: column;
    display: flex;
    gap: 8px;
    padding: 16px;
}

.navi-detail-card {
    background: white;
    border-radius: 8px;
    transition: all 0.3s ease;
    margin-bottom: 10px;
}

.navi-detail-card:hover .navi-detail-card-header {
    /* background: #F8F9FA; */
    box-shadow: rgba(171, 178, 185, 0.198) 0px 8px 12px;
}

.navi-detail-card-header {
    height: 75px;
    background: white;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border-radius: 8px;
}

.navi-detail-card-title {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 600;
    color: #333;
}

.navi-detail-card-content {
    padding: 10px 16px 16px 16px;
    background: white;
    height: 0;
    opacity: 0;
    transition: all 0.3s ease;
    visibility: hidden;
}

.navi-detail-expanded .navi-detail-card-content {
    height: auto;
    opacity: 1;
    visibility: visible;
}

/*add-new-button, which replace the previous certificate & education button*/
.add-new-button {
    border-radius: 8px;
    background-color: #ffffff;
    color: #7a7a7a;
    width: 100%;
    font-size: 15px;
    font-weight: 600;
    padding-top: 12px;
    padding-bottom: 12px;
    border: 2px solid #e5e5ea;
    cursor: pointer;
 }

 .add-new-button:hover {
    color: #6750A4;
    border: 2px solid #6750A4;
 }

/*Personal-information-section*/
.personal-info-content {
    margin: 10px auto;
}

.personal-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.personal-item {
    flex: 1;
    margin-right: 15px;
}

.personal-item:last-child {
    margin-right: 0;
}

/* Education Section CSS */
.education-section-container {
    margin: 10px auto;
}

.editor-subsection-title{
    font-size: 16px;
    font-weight: 600;
}

/* Skills Section CSS */
.skills-section-container {
    border-radius: 8px;
}

.skill-frame:hover {
    border: 1px solid #6750A4;
}

/* Certificates Container*/
.certificate-section-container {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    border: 1px #E5E5EA solid;
    margin: 10px auto;
}

.certificate-section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px auto;
    margin-bottom: 10px;
}

/* Summary */
.summary-section-container {
    background-color: white;
    border-radius: 8px;
    margin: 10px auto;
}

/* Cover Letter */
.CL-section-main-section{
    display: flex;
    flex-direction: column;
}

/* missing information Prompt */
.miss-info-badge {
    font-size: 14px;
    font-weight: 600;
    color: #FFFFFF;
    background-color: #DC2626;
    border: 1px solid #B91C1C;
    padding: 4px 12px;
    border-radius: 16px;
    margin-left: 8px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    transition: all 0.2s ease;
}

/* Keywords matching section */
.keyword-match-badge {
    font-size: 14px;
    font-weight: 500;
    color: #2a2a2a;
    background-color: #F0EDFF;
    padding: 4px 12px;
    border-radius: 16px;
    margin-left: 8px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    transition: all 0.2s ease;
}

.contains-keyword {
    border-left: 3px solid #6750a4;
}

.highlight-keyword {
    background-color: #DFD9FA;
    padding: 0 2px;
    border-radius: 2px;
}

.skill-frame.highlight-keyword {
    align-items: center;
    border: 1px solid;
    background-color: #Faf9ff;
    color: #6750a4;
    font-weight: 600;
    border-radius: 20px;
    display: inline-flex;
    gap: 10px;
    justify-content: center;
    padding: 2px 10px;
    position: relative;
    transition: all 0.2s ease;
}

@media (max-width: 1024px) {
    .navi-detail-outer {
        width: 100%;
    }
}