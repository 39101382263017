.resume-popup-modal-header {
    display: flex;
    align-items: center; /* Vertically align the items */
    justify-content: space-between; /* Space between the elements */
    padding: 10px 0;
}

.left-section {
    display: flex;
    align-items: center; /* Align h2 and download button horizontally on the same line */
}

.resume-popup-export-button {
    margin-left: 10px;
    cursor: pointer;
    width: 30px;
    height: 30px;
}

.resume-modal-preview-section {
    flex: 1;
    display: flex;
    flex-direction: column;
  }