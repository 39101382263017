.resubot-body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 20px 0px;
    box-sizing: border-box;
  }
  
  .resubot-page {
    align-items: center;
    background-color: #F9FBFC;
    padding: 124px 12% 164px;
    min-height: calc(100vh - 62px);
  }
  
  .resubot-block-container {
    position: relative;
    border: 1px solid #E5E5EA;
    border-radius: 4px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin-bottom: 30px;
    background-color: #FFFFFF;
    padding-top: 80px;
    min-height: 700px;
  }

  /* Base layout divider lines */
  .resubot-block-container::after,
  .resubot-block-container::before {
      content: '';
      position: absolute;
  }

  .resubot-block-container::after {
      top: 60px;
      left: 0;
      right: 0;
      height: 1px;
      background-color: #E5E5EA;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  }

  .resubot-block-container::before {
      top: 0;
      bottom: 0;
      left: 40%;
      width: 1px;
      background-color: #E5E5EA;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  }
  
  .resubot-block-title {
    position: absolute;
    top: 20px; /* Position the title above the line */
    left: 20px; /* Adjust for container padding */
    right: 3%; /* Adjust for container padding */
    display: flex;
    align-items: center;
    font-size: 16px; /* Adjust font size as needed */
    font-weight: bold; /* Make the text bold */
    cursor: pointer;
  }
  
  .resubot-back-button-img {
    margin-right: 6px; /* Add some space between the image and the text */
    width: 20px; /* Adjust the size of the image as needed */
    height: auto; /* Maintain aspect ratio */
  }
  
  .resubot-new-chat-title {
    position: absolute;
    top: 20px; /* Same top position as the Experience title */
    left: 43%; /* Position slightly to the right of the vertical line */
    font-size: 16px; /* Adjust font size as needed */
    font-weight: bold; /* Make the text bold */
  }
  
  .resubot-bottom-left-text {
    align-self: flex-start;
    max-width: 38%; /* Ensure text wraps if it exceeds 38% of container's width */
    font-size: 16px; /* Adjust font size as needed */
    word-wrap: break-word; /* Ensure long words are wrapped */
    margin-top: auto; /* Push the text to the bottom */
    padding-top: 20px; /* Adjust padding if needed */
  }

  .resubot-bottom-left-text-title {
    margin-bottom: 5px;
    line-height: 1.3;
  }
  
  .resubot-bottom-left-text-duration-location {
    margin-bottom: 15px;
  }

  /* Resubot chat section */

  .resubot-bottom-right-chat-container {
    position: absolute;
    top: 100px; /* Position 40px below the horizontal line */
    left: 43%; /* Align it to the right section */
    right: 20px; /* Ensure it doesn't overflow the container */
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Distribute space between chat history and input */
    height: calc(100% - 120px); /* Adjust height based on positioning */
  }
  
  .resubot-chat-history {
    height: calc(100% - 100px); /* Set height from 40px below the horizontal line to 40px above the input */
    overflow-y: auto; /* Make the chat history scrollable */
    display: flex;
    flex-direction: column; /* Stack chat messages vertically */
  }

  .resubot-chat-message {
    display: flex;
    align-items: flex-start; /* Align icon and message block */
    margin-bottom: 25px; /* Space between messages */
  }
  
  .resubot-chatbot-icon {
    width: 32px; /* Adjust the size of the chatbot icon */
    height: 32px; /* Maintain aspect ratio */
    margin-right: 10px; /* Space between icon and response text */
  }
  
  .resubot-chat-response {
    border: 1px solid #E5E5EA; /* Add border to the message block */
    border-radius: 8px; /* Round the corners of the message block */
    padding: 10px; /* Add padding inside the message block */
    background-color: #ffffff; 
    max-width: 80%; /* Ensure the message block doesn't take too much width */
    word-wrap: break-word; /* Ensure long words are wrapped */
    font-size: 14px; /* Adjust font size as needed */
    line-height: 1.4;
  }
  
  ul.resubot-response-list {
    padding-left: 15px;
    list-style-type: circle;
    font-weight: 600;
  }

  .resubot-user-prompt-message {
    display: flex;
    justify-content: flex-end; /* Align the user prompt to the right */
    margin-bottom: 25px; /* Space between messages */
  }
  
  .resubot-user-prompt {
    border-radius: 8px; /* Round the corners of the user prompt block */
    padding: 10px; /* Add padding inside the user prompt block */
    background-color: #F0EDFF; /* Background color for the user prompt block */
    max-width: 80%; /* Ensure the user prompt block doesn't take too much width */
    word-wrap: break-word; /* Ensure long words are wrapped */
    font-size: 14px; /* Adjust font size as needed */
    line-height: 1.4;
  }

  .resubot-chat-input-container {
    display: flex;
    align-items: center;
    padding: 3px; 
    background-color: #f6f6f6; /* Background color for the input container */
    border-radius: 25px; /* Rounded corners */
    width: 100%; /* Full width */
    box-sizing: border-box; /* Ensure padding is included in the width */
  }
  
  .resubot-chat-input {
    flex: 1;
    padding: 10px;
    border: none; /* Remove default border */
    border-radius: 25px; /* Rounded corners */
    font-size: 14px;
    background-color: transparent; /* Transparent background */
    outline: none; /* Remove default outline */
    resize: none; /* Disable manual resizing */
    height: 40px; /* Initial height */
    overflow-y: auto; /* Add scroll for vertical overflow */
    line-height: 1.4;
  }
  
  .resubot-chat-submit {
    background: none; /* Remove default background */
    border: none; /* Remove default border */
    cursor: pointer; /* Pointer cursor on hover */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px; /* Adjust padding for spacing */
  }
  
  .resubot-submit-icon {
    width: 24px; /* Adjust the size of the submit icon */
    height: 24px; /* Maintain aspect ratio */
  }

  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  .resubot-chat-response .dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 0 2px;
    background-color: #7A7A7A;
    border-radius: 50%;
    opacity: 0;
    animation: blink 1.4s infinite both;
  }
  
  .resubot-chat-response .dot:nth-child(1) {
    animation-delay: 0.2s;
  }
  
  .resubot-chat-response .dot:nth-child(2) {
    animation-delay: 0.4s;
  }
  
  .resubot-chat-response .dot:nth-child(3) {
    animation-delay: 0.6s;
  }

  .resubot-bold-text {
    font-weight: bold;
    color: #6750A4;
  }

  .resubot-chatbot-reply-section {
    display: flex;
    flex-direction: column; /* Stack the response and undo button vertically */
  }

  .resubot-undo-button {
    margin-top: 5px;
    padding: 5px 10px;
    background-color: #AAAAAA;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 14px;
    align-self: flex-start; /* Align to the start */
    color: #F9FBFC;
  }
  
  .resubot-undo-button img {
    margin-right: 5px;
    width: 12px; /* Adjust the width of the icon */
    height: 12px; /* Adjust the height of the icon */
  }
  
  .resubot-save-button {
    display: block;
    margin: 70px auto 0 auto; /* Center the button horizontally */
    padding: 15px 40px; /* Increase padding to make the button wider and taller */
    background-color: #FFFFFF; /* Background color */
    color: #000000; /* Text color */
    border: 2px solid #000000; /* Border color */
    border-radius: 25px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    font-size: 14px; /* Font size */
    font-weight: bold; /* Bold text */
    text-align: center; /* Center the text */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    width: 100%; /* Make the button full width */
  }
  
  .resubot-save-button:hover {
    background-color: #f0f0f0; /* Light gray background on hover */
  }

  /* Tablet styles (768px - 1024px) */
  @media (max-width: 1024px) {
    .resubot-page {
        padding: 100px 5% 100px;
    }

    .resubot-block-container {
        padding: 16px;
        padding-top: 70px;
    }

    .resubot-block-container::before {
        left: 45%; /* Adjust split for better tablet view */
    }

    .resubot-bottom-left-text {
        max-width: 43%;
    }

    .resubot-bottom-right-chat-container {
        left: 48%;
        right: 16px;
    }

    .resubot-chat-response,
    .resubot-user-prompt {
        max-width: 90%;
        font-size: 13px;
    }

    .resubot-save-button {
        padding: 12px 30px;
    }
  }

  /* Mobile styles (max-width: 767px) */
  @media (max-width: 767px) {
    .resubot-page {
        padding: 80px 16px 80px;
    }

    .resubot-block-container {
        padding: 12px;
        padding-top: 120px; /* Increased to accommodate stacked headers */
        min-height: 500px;
    }

    /* Remove vertical divider for mobile */
    .resubot-block-container::before {
        display: none;
    }

    /* Adjust horizontal divider */
    .resubot-block-container::after {
        top: 100px;
    }

    /* Stack the titles */
    .resubot-block-title,
    .resubot-new-chat-title {
        position: absolute;
        left: 12px;
        right: 12px;
    }

    .resubot-new-chat-title {
        top: 60px;
    }

    /* Make content full width */
    .resubot-bottom-left-text {
        max-width: 100%;
        margin-bottom: 16px;
        padding-top: 0;
    }

    .resubot-bottom-right-chat-container {
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        height: 400px; /* Fixed height for mobile */
        margin-top: 16px;
        border-top: 2px solid;
    }

    .resubot-chat-history {
        height: calc(100% - 60px);
    }

    .resubot-chat-message,
    .resubot-user-prompt-message {
        margin-bottom: 16px;
    }

    .resubot-chat-response,
    .resubot-user-prompt {
        max-width: 85%;
        font-size: 14px;
        padding: 8px 12px;
    }

    .resubot-chatbot-icon {
        width: 28px;
        height: 28px;
    }

    .resubot-chat-input-container {
        position: fixed;
        bottom: 16px;
        left: 16px;
        right: 16px;
        width: auto;
        background-color: white;
        box-shadow: 0 -2px 10px rgba(0,0,0,0.1);
        z-index: 1000;
    }

    .resubot-save-button {
        position: fixed;
        bottom: 80px;
        left: 16px;
        right: 16px;
        width: auto;
        z-index: 1000;
        margin: 0;
    }

    /* Adjust list styles for mobile */
    ul.resubot-response-list {
        padding-left: 12px;
        margin: 8px 0;
    }

    ul.resubot-response-list li {
        margin-bottom: 8px;
    }

    /* Mobile-specific animations */
    @keyframes slideUp {
        from {
            transform: translateY(100%);
        }
        to {
            transform: translateY(0);
        }
    }

    .resubot-chat-input-container,
    .resubot-save-button {
        animation: slideUp 0.3s ease-out;
    }
  }

  /* Handle very small screens */
  @media (max-width: 360px) {
    .resubot-page {
        padding: 60px 12px 60px;
    }

    .resubot-chat-response,
    .resubot-user-prompt {
        font-size: 13px;
    }

    .resubot-bottom-right-chat-container {
        height: 350px;
    }
  }

  /* Handle landscape orientation on mobile */
  @media (max-width: 767px) and (orientation: landscape) {
    .resubot-block-container {
        min-height: 450px;
    }

    .resubot-bottom-right-chat-container {
        height: 250px;
    }

    .resubot-chat-input-container {
        position: relative;
        bottom: auto;
        left: auto;
        right: auto;
        margin-top: 16px;
        box-shadow: none;
    }

    .resubot-save-button {
        position: relative;
        bottom: auto;
        left: auto;
        right: auto;
        margin-top: 16px;
    }
  }