.verification-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #6750A4;
}
  
.verification-window {
    background-color: white;
    padding: 40px 150px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
  
img.email-icon {
    height: 60px;
    width: 60px;
    margin-bottom: 20px;
}
  
h1.verify_email_title {
    margin-bottom: 10px;
    font-size: 28px;
    font-weight: 600;
    font-family: 'Open Sans';
    color: #2a2a2a;
}
  
p.verify_email_text {
    margin-bottom: 20px;
    font-size: 16px;
    color: #2a2a2a;
    font-family: 'Open Sans';
    font-weight: 400;
    max-width: 500px;
    margin: 20 auto;
    line-height: 1.5;
}
  
.send-btn {
    padding: 10px 100px;
    font-size: 16px;
    font-weight: 600;
    color: white;
    background-color: #6750A4;
    border: none;
    border-radius: 40px;
    cursor: pointer;
    transition: background-color 0.3s;
}

p.verify_error_message{
    margin-top: 20px;
    font-size: 14px;
    color: #b00000;
    font-family: 'Open Sans';
    font-weight: 400;
}