@media (max-width: 480px) {
  .contact-us {
    display: none;
  }
  .pricing-tab {
    display: none;
  }
}

.navigation {
  align-items: center;
  background-color: #ffffff;
  /* box-shadow: 0px 4px 4px #0000001a; */
  border-bottom: 1px solid #eae5ea; /* Add bottom line */
  display: flex;
  justify-content: space-between;
  padding: 12px 5%;
  position: relative;
  width: 100%;
}

.navigation .futuristic-modern {
  height: 30px;
  position: relative;
  width: 200px;
}

.button-primary {
  /* all: unset; */
  border: 2px #6750a4 solid;
  background-color: #6750a4;
  border-radius: 5px;
  box-sizing: border-box;
  height: 36px;
  position: relative;
  width: 160px;
  cursor: pointer;
  text-align: center;
}

a.contact-us-button {
  /* all: unset; */
  border: none;
  background-color: #ffffff;
  outline: none;
  height: 36px;
  position: relative;
  width: 100px;
  cursor: pointer;
  text-align: center;
}

.button-primary-text {
  color: #ffffff;
  font-family: "Open Sans", Helvetica;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
}

.button-primary:hover {
  /* all: unset; */
  background-color: #9a82db;
  border: 2px #9a82db solid;
}

.button-primary:hover .button-primary-text {
  color: #faf9ff;
}

.button-secondary-text {
  color: #7a7a7a;
  font-family: "Open Sans", Helvetica;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
}

.button-secondary-text:hover {
  color: #6750a4;
  font-weight: 700;
}

.navigation .credit-info {
  cursor: pointer;
}

.navigation .button-discord {
  all: unset;
  cursor: pointer;
}

.button-discord:hover {
  opacity: 50%;
}

.navigation .button-discord .discord-icon {
  width: 28px;
  height: 28px;
}

.navigation-buttons {
  display: flex;
  align-items: center;
  gap: 40px;
}

.profile-icon {
  width: 36px;
  height: 36px;
}

.wallet-icon {
  width: 22px;
  height: 22px;
}

.credit-info {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer; /* Change cursor to pointer */
}

/* Black Friday Offer decoration */
.pricing-tab {
  position: relative;
  padding: 6px 12px;
  border-radius: 16px;
  text-decoration: none;
  transition: all 0.3s ease;
}

/* Animated background for pricing tab */
.pricing-tab::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, #381e72, #6b4eae, #381e72);
  border-radius: 16px;
  opacity: 0.1;
  z-index: -1;
  animation: pricingPulse 2s ease-in-out infinite;
}

/* Small tag for "Black Friday" */
.pricing-tab::after {
  content: "40% Off";
  position: absolute;
  top: -10px;
  right: -10px;
  background: #381e72;
  color: white;
  font-size: 9px;
  font-weight: bold;
  padding: 4px 8px;
  border-radius: 8px;
  animation: tagBounce 2s ease-in-out infinite;
}

/* Hover effect */
.pricing-tab:hover {
  transform: translateY(-2px);
}

.pricing-tab:hover::before {
  opacity: 0.2;
}

/* Pricing button animations */
@keyframes pricingPulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes tagBounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-2px);
  }
}

/* Modify your existing button-secondary-text style for pricing */
.pricing-tab .button-secondary-text {
  color: #381e72;
  font-weight: 600;
  position: relative;
}

.pricing-tab:hover .button-secondary-text {
  color: #381e72;
  font-weight: 700;
}
