body{
  justify-content: center;
  align-items: center;
}
html{
  height: 0 !important;
}

.pricing{
  background: linear-gradient(#EDE7FE, #F6F3FE);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.pricing-title{
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  justify-content: center;
}

.title-heading{
  color: #2a2a2a;
  font-family: "Open Sans";
  font-size: 48px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: 80px;
  margin-bottom: 10px;
}

.title-sublabel{
  color: #7a7a7a;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
}

.title-label{
  color:#2a2a2a;
  font-family: "Open Sans";
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 20px;
}

.pricing-details-box{
 display: flex;
 flex-direction: column;
 align-items: center;
 /* border: 1px solid #7a7a7a; */
 border-radius: 8px;
 padding: 10px;
 margin-bottom: 10px;
}


.pricingcard-container{
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  flex: 1;  /* This makes the container take up available space */
}

.pricingcard-top{
  background-color: #FFFFFF;
  border-radius: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 20px;
  /* display: flex;
  flex-direction: column; */
  width: 80%;
  box-sizing: border-box;
  max-width: 1200px;
}

.pricecard-top-content{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 18px;
}

.ledt-side-content{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  text-align: left;
}

.card-tag{
  background-color: #381E72;
  border-radius: 12px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 10px;
  padding-right: 10px;
  color: #FFFFFF;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  width: fit-content;
}

.card-name{
  color:#2a2a2a;
  font-family: "Open Sans";
  margin-bottom: 46px;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
}

.card-price{
  align-items: end;
  display: flex;
  gap: 8px;
  height: 60px;
  margin-bottom: 0px;
}

.new-price{
  color: #381E72;
  font-size: 40px;
  font-weight: 700;
  font-family: "Open Sans";

}

.old-price{
  color: #7a7a7a;
  font-size: 16px;
  font-weight: 300;
  font-family: "Open Sans";
  text-decoration: line-through;

}

.old-price-clear{
  color: #7a7a7a;
  font-size: 16px;
  font-weight: 300;
  font-family: "Open Sans";

}

.price-comment{
  color: #7a7a7a;
  font-size: 14px;
  font-weight: 300;
  font-family: "Open Sans";
  margin-top: 10px;
}

.right-side-content{
  display: flex;
  flex-direction: column;
}

.plan-include{
  grid-column-gap: 8px;
  grid-row-gap: 5px;
  color: #7a7a7a;
  align-items: center;
  display: flex;
  margin-bottom: 6px;
}

.bottom-plan-include{
  grid-column-gap: 8px;
  grid-row-gap: 5px;
  color: #7a7a7a;
  align-items: center;
  display: flex;
}

img.checkmark-icon{
  width: 20px;
  height: 20px;

}

.plan-include-content{
  font-size: 12px;
  font-weight: 300;
  color: #7a7a7a;
  font-family: "Open Sans";
}

.subscribe-button{
  background-color: #381E72;
  color: #ffffff;
  border: none;
  padding: 10px 50px;
  border-radius: 30px;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s ease;
  font-weight: 600;
}

.subscribe-button-subscribed {
  background-color: gray;
  cursor: not-allowed;
  color: #ffffff;
  border: none;
  padding: 10px 50px;
  border-radius: 30px;
  width: 100%;
  transition: background-color 0.3s ease;
  font-weight: 600;
}

.pricingcard-bottom{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  box-sizing: border-box;
  width: 80%;
  gap: 20px;
  max-width: 1200px;
}

.bottom-card-1,
.bottom-card-2,
.bottom-card-3{
  background-color: #FFFFFF;
  border-radius: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* width: 285px; */
  width: 32%;
  box-sizing: border-box;
  gap: 10px;
}

.bottomcard-name{
  color:#2a2a2a;
  font-family: "Open Sans";
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
}

.bottomcard-price{
  color: #381E72;
  font-size: 40px;
  font-weight: 700;
  font-family: "Open Sans";
  margin-bottom: 10px;
}


.subscribe-button-small{
  background-color: #ffffff;
  color: #381E72;
  font-size: 14px;
  font-weight: 600;
  border-style: solid;
  border-color: #381E72;
  border-width: medium;
  padding: 10px 60px;
  border-radius: 30px;
  width: 100%;
  transition: background-color 0.3s ease;
}


.plan-include-container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer{
    margin-top:80px;
}

.foot-box {
  margin-top: auto;  /* Pushes footer to bottom */
  width: 100%;
}

/* Responsive adjustments */
@media (max-width: 1250px) {
  .title-heading {
    font-size: 40px;
    margin-top: 40px;
    text-align: center;
    padding: 0 20px;
  }
  .bottom-card-1,
  .bottom-card-2,
  .bottom-card-3{
      width: 48%;
  }
}

/* Black Friday offer decoration */
.pricingcard-top,
.bottom-card-3 {
  position: relative;
  background-color: #FFFFFF;
  border: 2px solid transparent;
  background-clip: padding-box;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  z-index: 1;
}

/* Background layer to prevent color flash */
.pricingcard-top::after,
.bottom-card-3::after {
  content: '';
  position: absolute;
  inset: 0;
  background: #FFFFFF;
  border-radius: 10px;
  z-index: -1;
}

/* Gradient border */
.pricingcard-top::before,
.bottom-card-3::before {
  content: '';
  position: absolute;
  top: -2px;
  right: -2px;
  bottom: -2px;
  left: -2px;
  background: linear-gradient(45deg, #381E72, #6B4EAE, #381E72);
  z-index: -2;
  border-radius: 12px;
  animation: borderAnimation 3s ease infinite;
  background-size: 200% 200%;
}

/* Hover effects */
.pricingcard-top:hover,
.bottom-card-3:hover {
  transform: translateY(-4px);
  box-shadow: 0 10px 20px rgba(56, 30, 114, 0.15);
}

/* Keep the rest of your animations and styles exactly the same */
@keyframes borderAnimation {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.card-tag {
  position: relative;
  overflow: hidden;
}

.pricingcard-top .card-tag::after,
.bottom-card-3 .card-tag::after {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    to right,
    transparent,
    rgba(255, 255, 255, 0.3),
    transparent
  );
  transform: rotate(45deg);
  animation: shine 3s infinite;
}

@keyframes shine {
  0% { transform: translateX(-100%) rotate(45deg); }
  100% { transform: translateX(100%) rotate(45deg); }
}

/* Price animation */
.pricingcard-top .new-price,
.bottom-card-3 .new-price {
  position: relative;
  display: inline-block;
  animation: pulsePricing 2s infinite;
}

@keyframes pulsePricing {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

/* Button styles */
.pricingcard-top .subscribe-button,
.bottom-card-3 .subscribe-button-small {
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}

.pricingcard-top .subscribe-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(56, 30, 114, 0.2);
  background-color: #4A2B8C;
}

.subscribe-button-small:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(56, 30, 114, 0.2);
  background-color: #381E72;
  color: #FFFFFF;
}

@media (max-width: 1250px) {
  .pricingcard-top::before,
  .bottom-card-3::before {
    border-radius: 10px;
  }
}

/* pricing text */
/* Add special highlight for the offer text */
.title-label strong {
    color: #381E72;
    background: linear-gradient(120deg, rgba(56, 30, 114, 0.1) 0%, rgba(107, 78, 174, 0.1) 100%);
    padding: 2px 8px;
    border-radius: 4px;
    font-weight: 700;
    animation: highlightPulse 2s infinite;
}

@keyframes highlightPulse {
0%, 100% {
    opacity: 1;
}
50% {
    opacity: 0.8;
}
}

/* Responsive styles */
@media (max-width: 768px) {
  /* Title section */
  .title-heading {
    font-size: 32px;
    margin-top: 40px;
    text-align: center;
    padding: 0 20px;
  }

  .title-label {
    font-size: 16px;
    text-align: center;
    padding: 0 20px;
  }

  .title-sublabel {
    font-size: 12px;
    text-align: center;
  }

  /* Card container */
  .pricingcard-container {
    margin-top: 40px;
    margin-bottom: 40px;
    padding: 0 15px;
  }

  /* Top card */
  .pricingcard-top {
    width: 100%;
    padding: 15px;
  }

  .pricecard-top-content {
    flex-direction: column;
    align-items: flex-start;
  }

  .right-side-content {
    margin-top: 20px;
    width: 100%;
  }

  /* Bottom cards */
  .pricingcard-bottom {
    width: 100%;
    flex-direction: column;
    gap: 15px;
  }

  .bottom-card-1,
  .bottom-card-2,
  .bottom-card-3 {
    width: 100%;
    padding: 15px;
  }

  /* Price section */
  .card-price {
    height: auto;
    margin-bottom: 10px;
  }

  .new-price {
    font-size: 32px;
  }

  .old-price {
    font-size: 14px;
    align-self: center;
  }

  /* Buttons */
  .subscribe-button,
  .subscribe-button-small {
    padding: 12px 30px;
    font-size: 14px;
  }

  .subscribe-button-container {
    width: 100%;
  }

  /* Plan includes */
  .plan-include {
    margin-bottom: 8px;
  }

  .plan-include-content {
    font-size: 12px;
  }

  /* Card tag */
  .card-tag {
    font-size: 12px;
    margin-bottom: 10px;
  }

  .card-name {
    font-size: 16px;
    margin-bottom: 20px;
  }

  /* Footer adjustment */
  .foot-box {
    margin-top: 40px;
  }
}

/* Additional improvements for very small screens */
@media (max-width: 380px) {
  .title-heading {
    font-size: 28px;
  }

  .title-label {
    font-size: 14px;
  }

  .new-price {
    font-size: 28px;
  }

  .card-name {
    font-size: 14px;
  }
}