.applications-card {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 2px 5px #7979791a;
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  transition: background-color 0.3s ease; /* Smooth transition for background color */
}

.applications-card:hover {
  cursor: pointer;
}

.applications-card:not(.expanded):hover {
  border: 1px solid #e5e5ea;
}

.applications-header {
  display: flex;
  justify-content: space-between;
}

.company-info {
  display: flex;
  align-items: center;
  gap: 15px;
}

.company-icon {
  width: 70px;
  height: 70px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.logo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.company-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.applications-company-name {
  color: #7a7a7a;
  font-weight: 400;
  font-size: 16px;
  margin: 0;
}

.applications-position {
  color: #2a2a2a;
  font-weight: 600;
  font-size: 16px;
  margin: 5px 0 25px 0;
}

.applications-actions {
  display: flex;
  gap: 10px;
}

.view-btn {
  background-color: #f0f0f0;
  border: none;
  padding: 8px 12px;
  border-radius: 8px;
  font-size: 0.9em;
  cursor: pointer;
  color: #666;
  transition: background-color 0.2s ease;
}

.view-btn:hover {
  background-color: #e5e5ea;
}

/* Adjusting the applications-meta section */
.applications-meta {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.status-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #8884d8;
}

.applications-status-applied .status-dot {
  background-color: #8884d8;
}

.applications-status-screen .status-dot {
  background-color: #faad14;
}

.applications-status-offer .status-dot {
  background-color: #52c41a;
}

/* Company date alignment */
.applications-date {
  color: #888;
  font-size: 0.85em;
  align-self: flex-end; /* Aligns the date to the right */
  margin-top: auto; /* Pushes the date to the bottom of the section */
  margin-bottom: 8px;
}

.applications-status-dropdown-item {
  padding: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.applications-status-dropdown-item:hover {
  background-color: #f6f6f6;
}

.application-card-divider {
  display: block;
  border-top: 1px solid #d9d9d9; /* Line style */
  margin: 10px 0; /* Optional: adjust spacing */
}

@media (max-width: 835px) {
  .applications-card {
    margin: 1%;
  }
  .applications-header {
    width: 100%;
    font-size: 14px;
  }
  .applications-actions {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .applications-meta {
    width: 30%;
    align-items: stretch;
    align-items: center;
  }
  .company-info {
    display: flex;
    width: 70%;
    align-items: stretch;
  }
  .company-icon {
    height: auto;
    width: 30%;
  }
  .company-icon img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
  .company-details {
    flex-direction: column;
  }

  .view-btn {
    width: auto;
    white-space: nowrap;
  }
}
