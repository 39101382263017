.slider {
    width: 100px; /* Adjust the width as needed */
  }
  
  .template-tool-bar {
    display: flex;
    align-items: center;
    padding: 4px;
    border: 1px solid #E5E5EA;
    border-radius: 8px;
    background-color: #FFFFFF;
  }
  
  .toolbar-section {
    display: flex;
    align-items: center;
    position: relative; /* Added for dropdown positioning */
    margin: 0 8px;
  }

  .toolbar-section-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .toolbar-section-slider {
    display: flex;
    align-items: center;
    position: relative;
    padding: 2px 8px;
    border-radius: 8px;
  }

  .hover-text {
    display: none; /* Hide the text by default */
    position: absolute;
    top: 100%; /* Position the text below the div */
    left: 50%;
    transform: translateX(-50%); /* Center the text horizontally */
    background-color: #fff;
    padding: 4px 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
    z-index: 10; /* Ensure the text appears above other elements */
  }
  
  .toolbar-section-slider:hover .hover-text {
    display: block; /* Show the text when hovered */
  }
  
  .toolbar-icon {
    width: 16px;
    height: 16px;
    margin: 0 4px;
  }
  
  .toolbar-button {
    position: relative;
    border: none;
    cursor: pointer;
    text-align: center;
    margin: 0 4px;
    background: none; /* Remove button background */
  }

  .toolbar-button:hover .hover-text {
    display: block; /* Show the text when the specific button is hovered */
  }
  
  .toolbar-span {
    display: inline-block;
    width: 35px; /* Fixed width for the fontsize number */
    text-align: center;
  }
  
  
  .dropdown-bubble {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 8px;
    padding: 8px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    white-space: nowrap;
  }
    
  .vertical-divider {
    height: 24px;
    width: 1px;
    background-color: #ccc;
    margin: 0 8px; /* Increased space around the divider */
  }
  
  .bold-keywords-checkbox {
    display: flex;
    align-items: center;
  }
  
  .slider-container {
    display: flex;
    align-items: center;
  }
  
  .slider-value {
    margin: 0 8px; /* Adjust the spacing as needed */
    font-size: 12px; /* Adjust font size as needed */
    font-weight: 600;
  }

  .slider {
    -webkit-appearance: none; /* Remove default appearance */
    width: 180px; /* Adjust the width as needed */
    height: 2px; /* Thinner slider line */
    background: #AAAAAA; /* Background color of the slider */
    border-radius: 2px; /* Rounded edges for the slider line */
    outline: none; /* Remove outline */
    cursor: pointer; /* Change cursor to pointer */
  }
  
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none; /* Remove default appearance */
    appearance: none;
    width: 16px; /* Size of the thumb */
    height: 16px; /* Size of the thumb */
    background: #6750A4; /* Purple color for the thumb */
    border-radius: 50%; /* Make the thumb circular */
    cursor: pointer; /* Change cursor to pointer */
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2); /* Optional: add shadow for a better look */
  }
  
  .slider::-moz-range-thumb {
    width: 16px; /* Size of the thumb */
    height: 16px; /* Size of the thumb */
    background: #6750A4; /* Purple color for the thumb */
    border-radius: 50%; /* Make the thumb circular */
    cursor: pointer; /* Change cursor to pointer */
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2); /* Optional: add shadow for a better look */
  }

  .section-order-dropdown {
    width: 240px; /* 设置一个固定宽度 */
    max-height: 300px; /* 限制最大高度 */
    overflow-y: auto; /* 内容过多时显示滚动条 */
    padding: 8px;
  }
  
  .section-order-list {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  
  /* 优化滚动条样式 */
  .section-order-dropdown::-webkit-scrollbar {
    width: 6px;
  }
  
  .section-order-dropdown::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 3px;
  }
  
  .section-order-dropdown::-webkit-scrollbar-thumb {
    background: #AAAAAA;
    border-radius: 3px;
  }
  
  .section-order-dropdown::-webkit-scrollbar-thumb:hover {
    background: #888;
  }
  
  /* 拖拽项的hover状态 */
  .section-item:hover {
    background-color: #F8F5FF;
    border-color: #381E72;
  }