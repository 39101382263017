body{
    justify-content: center;
    align-items: center;
}
html{
    height: 0 !important;
}
  

.body-container{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #8E8e8e;
  display: flex;
  justify-content: center;
  align-items: center;
}

.successwindow-container{
  background-color: #fff; /* White background */
  padding: 30px 20px 20px 20px;
  border-radius: 8px;
  text-align: center;
  width: 50%;
}

.successwindow-container h1{
  font-size: 24px;
  font-weight: 700;
  font-family: "Open Sans";
  color: #2a2a2a;
  margin-bottom: 20px;
}

.successwindow-container p{
  font-size: 16px;
  font-weight: 600;
  font-family: "Open Sans";
  color: #AAAAAA;
  margin-bottom: 30px;
  line-height: 1.2;
}

a.subscribe-button{
  display: flex;
  justify-content: center;
  background-color: #6750A4;
  color: #ffffff;
  border: none;
  padding: 10px 50px 10px 50px;
  border-radius: 10px;
  cursor: pointer;
  width: 100%;
  font-weight: 600;
  transition: background-color 0.3s ease;
  text-align: center;
}