.frame-wrapper {
    align-items: center;
    border: 1px solid;
    border-color: #F6F6F6;
    background-color: #F6F6F6;
    color: #2a2a2a;
    font-weight: 600;
    border-radius: 20px;
    display: inline-flex;
    gap: 10px;
    justify-content: center;
    padding: 2px 10px;
    position: relative;
  }
  
  .frame-wrapper .java {
    background-color: #F6F6F6;
    font-family: "Open Sans", Helvetica;
    color: #2a2a2a;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
  }
  
  .frame-wrapper .delete-icon {
    width: 14px;
    height: 14px;
    cursor: pointer;
    object-fit: contain;
  }