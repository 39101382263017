body {
    font-family: 'Open Sans', sans-serif !important;
    background-color: white;
  }
  
  .getStarted-body{
   padding-bottom: 60px;
  }
  
  .get-started-header{
    align-items: center;
    display: flex;
    margin-top: 40px;
    justify-content: center;
    color: #2a2a2a;
    font-family: "Open Sans";
    font-size: 28px;
    font-weight: 600;
  }
  
  .bottom-continue-button {
    display: flex;
    justify-content: center;
     max-width: 1500px; /* or any max width */
    margin: 0 auto; /* centering the container */
  }
  
  .flex-content{
    flex: 0 0 66.66%; /* approx 8/12 columns */
  }
  
  .card.selected {
    background-color: #FAF9FF;
    box-shadow: 0 1px 2px #0000001a;
  }
  
  .dashboard-resume-pic{
    width: 60px;
    height: 60px;
    border-radius: 4px;
    margin-bottom: 20px;
  }
  
  h3.card-title{
    font-size: 20px;
    font-weight: 700;
    font-family: "Open Sans";
    margin-bottom: 10px;
  }
  
  p.resume-price{
    color: #7A7A7A;
    font-size: 18px;
    font-weight: 600;
    font-family: "Open Sans";
    margin-bottom: 10px;
    margin-top: 20px;
  }
  
  p.service-description{
    font-size: 14px;
    font-weight: 500;
    font-family: "Open Sans";
    margin-bottom: 10px;
    max-width: 240px;
  }
  
  button.continue-button{
    margin-top: 40px;
    margin-right: 50px;
    margin-left: 50px;
    padding: 10px 20px;
    max-width: 80%;
    cursor: pointer;
    background-color: #6750A4;
    color: #ffffff;
    font-size: 18px;
    font-weight: 700;
    border-radius: 30px;
    border: none;
  }
  
  button.continue-button-disabled{
    margin-top: 40px;
    margin-right: 50px;
    margin-left: 50px;
    padding: 10px 20px;
    max-width: 80%;
    cursor: pointer;
    background-color: #AAAAAA;
    color: #ffffff;
    font-size: 18px;
    font-weight: 700;
    border-radius: 30px;
    border: none;
  }
  
  .option {
    width: 40%;
    height: 40vh;
    background: #FAF9FF;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    cursor: pointer;
  }
  
  .option:hover {
    border: 2px solid #6750A4;
  }
  
  .option.selected {
    border: 2px solid #6750A4;
  }
  
  .option h1 {
    color: #AAAAAA;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .option.selected h1 {
    color: #2A2A2A;
  }
  
  .option p {
    width: 68%;
    color: #AAAAAA;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  
  .option.selected p {
    color: #2A2A2A;
  }
  .next-button:hover {
    background-color: #7a62c2;
  }

/* manually control height */
.resume-coverletter-container {
    display: flex;
    align-items: stretch;
    padding: 0px;
    margin-top: 1vh;
    gap: 20px;
    justify-content: center;
    width: 100%;
}

.resume-coverletter-container .card {
    min-height: 250px;
    border-radius: 20px;
    padding: 20px;
    padding-left: 30px;
    background-color: #FFFFFF;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-shadow: none;
    text-align: left;
    border: 1px solid #E5E5EA;
}

.resume-coverletter-container .card.resume-card {
    width: 55%;
}

.resume-coverletter-container .card.cover-letter-card {
    width: 45%;
}

/* Flex container for header content (image + bubble) */
.get-started-card-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}

/* Bubble is aligned within the flex container */
.included-bubble {
    background-color: #F0EDFF;
    color: #2A2A2A;
    padding: 10px 20px;
    border-radius: 15px;
    font-size: 16px;
    font-weight: 800;
    text-align: center;
}

/* Divider line */
.resume-coverletter-container .card-divider {
    border: none;
    border-top: 1px solid #7A7A7A;
    width: 100%;
    margin: 10px 0;
}

.resume-coverletter-container .card:hover {
    border: 1px solid #6750a4;
}

.resume-coverletter-container .card.selected {
    border: 2px solid #6750a4;
}

.dashboard-revision-div {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    position: relative;
    width: 100%;
    margin-top: 4vh;
    margin-bottom: 2vh;
    padding-left: 18%;
    padding-right: 18%;
}

.dashboard-revision-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 1vh;
  margin-bottom: 2vh;
  padding-left: 3%;
  padding-right: 18%;
  background-color: #f9f9ff;
  border-radius: 10px;
  height: 50px;
  color: #333;
  font-size: 16px;
  border: none;
}

.taget-company-input-title{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
}

.taget-company-input-title a{
  font-size: 12px;
  color:#bababa;
  text-decoration: none;
}

.option-box{
    display: flex;
    gap: 30px;
}
  
.option-button{
    all: unset;
    align-items: center;
    background-color: #c7c7c7;
    border-radius: 10px;
    box-sizing: border-box;
    display: inline-flex;
    gap: 10px;
    justify-content: center;
    padding: 10px 40px;
    position: relative;
    cursor: pointer;
    min-width: 35%;
    color: #ffffff;
    font-family: "Open Sans", Helvetica;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.option-button:hover {
    background-color: #9A82DB;
}
  
.option-button.selected {
    background-color: #6750a4;
}

.back-button-img {
    height: 24px;
    width: 24px;
  }
  
.back-button-text{
    padding-left: 10px;
    font-family: "Open Sans", Helvetica;
    font-size: 16;
    font-weight: 500;
}

.insufficient-balance {
  text-align: center;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 50px;
  font-weight: 600;
}

.insufficient-balance a{
  color: #B00000;
  text-decoration: underline;
}

.sufficient-balance {
  text-align: center;
  font-size: 14px;
  color: #aaaaaa;
  margin-top: 10px;
  margin-bottom: 50px;
  font-weight: 600;
}

/* .description-box {
  display: flex;
  justify-content: space-between; 
  align-items: center;
  margin-bottom: 10px;
}
.description-text {
  font-family: "Open Sans", Helvetica;
  font-size: 13px;
  font-weight: 400;
  color: #AAAAAA;         
}
.counter-text{
  margin-left: 6px;
} */

.description-box {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Optional: if you want the text vertically centered */
  width: 100%;
  margin-bottom: 10px;
}

.description-text {
  font-family: "Open Sans", Helvetica;
  font-size: 13px;
  font-weight: 400;
  color: #AAAAAA;         
}

.counter-text {
  font-family: "Open Sans", Helvetica;
}

.form-title {
    font-family: "Open Sans", Helvetica;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 10px !important;
  }
  .dashboard-revision-div {
    position: relative;
    width: 100%; /* Full width of the parent container to ensure responsiveness */
  }
  
  .company-container {
    width: 100%; /* Set input field to 100% width of the parent */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box; /* Ensures padding is included in width */
  }
  

  .styled-dropdown-container {
    top: 100%;
    z-index: 10;
    box-sizing: border-box;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 6px;
    /* width: 60%; */
    min-width: 64%;
    /* max-height: 250px; */
    /* margin-top: -10px; */
    position: absolute;
    overflow-y: auto;
    box-shadow: 0 4px 8px #0000001a;
}
  
  .styled-dropdown-item {
    display: flex;
    align-items: center;
    padding: 12px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .styled-dropdown-item:hover {
    background-color: #f4f4f4;
  }
  
  .dropdown-logo img {
    width: 30px;
    height: 30px;
    border-radius: 6px;
    margin-right: 10px;
  }
  
  .dropdown-content {
    display: flex;
    flex-direction: column;
  }
  
  .company-name {
    font-size: 16px;
    font-weight: 500;
    color: #333;
  }
  
  .company-domain {
    font-size: 14px;
    color: #999;
    margin-top: 4px;
  }

  .dashboard-revision-div input::placeholder {
    color: #b3b2b2; /* Example placeholder color */
    font-size: 12px; /* Optional: Change the font size */
  }

  @media screen and (max-width: 1024px) {
    .dashboard-revision-div {
        padding: 0 3%;
    }

    .resume-coverletter-container .card.resume-card,
    .resume-coverletter-container .card.cover-letter-card {
        max-width: 100%;
        width: 100%;
    }

    .option-button {
        min-width: 100px;
        padding: 8px 16px;
    }
  }

  @media screen and (max-width: 768px) {
    .resume-coverletter-container {
        flex-direction: column;
        gap: 15px;
        padding: 10px;
    }

    .dashboard-revision-div {
        padding: 0 15px;
    }

    .get-started-card-header {
        flex-direction: column;
        gap: 10px;
    }

    .included-bubble {
        font-size: 14px;
        padding: 8px 16px;
    }

    .option-box {
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
    }

    .option-button {
        width: 100%;
    }
  }

  @media screen and (max-width: 480px) {
    .upload-container {
        padding: 10px;
    }

    .dashboard-resume-pic {
        width: 50px;
        height: 50px;
    }

    h3.card-title {
        font-size: 18px;
    }

    p.resume-price {
        font-size: 16px;
    }

    .included-bubble {
        font-size: 12px;
        padding: 6px 12px;
    }

    .title-3 {
        font-size: 20px;
    }
  }