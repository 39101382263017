.stepper-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.steps {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 800px;
  margin-bottom: 20px;
  position: relative;
}

.step {
  display: flex;
  align-items: center;
  padding: 20px 10px;
  flex: 1;
  margin: 0 20px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.step-number {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #e5e5ea; /* Gray for inactive */
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  margin-right: 10px;
  border: 2px solid #e5e5ea;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.step-number.active {
  background-color: #6750a4; /* Purple for active */
  border-color: #6750a4;
  content: "✔";
}

.step-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.step-title {
  font-weight: 400;
  font-size: 12px;
}

.step-description {
  font-size: 16px;
  font-weight: 600;
}

/* Horizontal line at the end of each step */
.step-line {
  width: 40px;
  height: 2px;
  background-color: #aaaaaa;
  transform: translateY(40px);
  z-index: 1;
}

.step:last-child .step-line {
  display: none;
}

/* Image container with fade effect and light purple box */
.image-container {
  width: 100%;
  max-width: 800px;
}

.image-box {
  padding: 40px;
  background-color: #faf9ff; /* Light purple background */
  border-radius: 8px;
}

.image-box img {
  max-width: 80%;
  height: auto;
  border-radius: 8px;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.image-fade {
  opacity: 1;
}

@media (max-width: 480px) {
  .stepper-container {
    transform: scale(0.5);
    transform-origin: top center;
    margin-top: 0;
    margin-bottom: 0;
  }
}