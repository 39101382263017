*,
*::before,
*::after {
  box-sizing: border-box;
}
.dashboard-results {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #7a7a7a;
}
.announcement-banner {
  width: 100%;
  margin-top: 64px;
  display: flex;
  background-color: #6750a4;
  padding: 15px;
  align-items: center;
  justify-content: center;
}

.banner-text {
  color: #ffffff;
  font-family: "Open Sans";
  font-weight: 500;
}

/* Main box styles */
.db_contents {
  background-color: #ffffff;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  /* min-height: 900px; */
  /* align-items: flex-start; */
  align-content: stretch;
  flex-wrap: nowrap;
  height: fit-content;
  place-content: stretch;
}
/* ------------------------------Sidebar --------------------------------- */
.db_sidebar {
  /* position: fixed;
    top:100px;
    left: 0;
    height: clac(100vh - 64px);
    overflow-y:auto; */
  /* width: 20%; */
  padding: 30px;
  border-right: 1px solid #e5e5ea;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.dashboard-nag-close {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.dashboard-nag-close .futuristic-modern {
  height: 24px;
}

.dashboard-nag-close .logo-nmv {
  height: 24px;
}

.upload_resume {
  margin-bottom: 30px;
}
.upload_btn {
  width: 100%;
  border: #6750a4 2px solid;
  border-radius: 8px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: white;
  background-color: #6750a4;
  padding: 12px 20px;
}

.upload_btn:hover {
  color: #6750a4;
  background-color: white;
}
.username {
  font-size: 20px;
  font-weight: 600;
  display: block;
  margin-bottom: 30px;
  padding-top: 20px;
  max-width: 100%;
  overflow-wrap: break-word;
  white-space: normal;
}
.db_side-list {
  flex: 1;
  /* Grow to fill available space */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.db_side-list button,
.dashboard-bottom-buttons button {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 10px;
  color: #7a7a7a;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 40px;
  text-align: left;
  margin-bottom: 20px;
  border: none;
  background-color: transparent;
}

.dashboard-bottom-buttons {
  margin-top: auto;
  position: sticky;
  bottom: 0;
  margin-bottom: 20px;
}

.db_side-list button img,
.dashboard-bottom-buttons button img {
  margin-right: 12px;
  width: 20px;
  height: 20px;
}

.db_side-list button:hover,
.dashboard-bottom-buttons button:hover,
.db_side-list .selected-button {
  background-color: #f6f6f6;
  color: #6750a4;
  border-radius: 40px;
}
/* Hover effect on the button to change the image source */
.db_side-list button:hover img.revision-history {
  content: url("../../../static/img/revision_history_2.png");
}

.db_side-list button:hover img.my-resume {
  content: url("../../../static/img/my_resume_2.png");
}

.db_side-list button:hover img.billing-history {
  content: url("../../../static/img/billing_icon_2.png");
}

.useremail {
  font-size: 16px;
  display: block;
  padding: 12px 10px;
  overflow-wrap: break-word;
  white-space: normal;
  margin-top: 50px;
}
.logout {
  display: flex;
  align-items: center;
  padding: 12px 10px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: #7a7a7a;
  font-size: 16px;
  margin-top: 10px;
}

.logout img {
  margin-right: 12px;
  width: 20px;
  height: 20px;
}

@media (max-width: 768px) {
  .username {
    font-size: 16px;
  }
}
/* ------------------------------main --------------------------------- */
.db_main-box {
  /* margin-left: 20%; */
  /* width: 80%; */
  flex-grow: 1;
  background-color: #f9fbfc;
  flex-direction: column;
  display: flex;
  min-height: 100vh;
  padding-bottom: 30px;
  /* position: relative; */
}
.db_main-box h4 {
  margin: 50px 0 0 50px;
  font-size: 20px;
  font-weight: 600;
}

.db_main-box .blank_box {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align items to the top */
  align-items: center; /* Center items horizontally */
  margin: 0 auto; /* Center horizontally only */
  padding: 0 15%;
}

.blank_box img,
.blank_box p {
  padding-bottom: 20px;
}
.blank_box img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-top: 150px;
}

.blank_box p {
  margin: 0;
  color: #605f5f;
  font-size: 16px;
  line-height: 1.3;
}
.blank_box button {
  padding: 12px 20px;
  font-weight: 700;
  border: #6750a4 2px solid;
  border-radius: 8px;
  color: #6750a4;
  background-color: transparent;
}

.blank_box button:hover {
  color: white;
  background-color: #6750a4;
}

/* ------------------------------billing history --------------------------------- */
.db_main-box #resuHistory {
  margin: 50px 0 0 50px; /* Adjust as needed */
  width: 80%; /* Adjust as needed */
}

.billing-credit-balance {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 30px;
  border-radius: 8px;
  background-color: #ffffff;
  gap: 5px;
  margin-bottom: 50px;
}

.billing-credit-balance p.billing-credit-balance-number {
  font-size: 28px;
  font-weight: 700;
  color: #6750a4;
}

.credit-balance-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.credit-balance-header p {
  font-size: 16px;
  font-weight: 600;
}

button.view-plans-button {
  font-size: 16px;
  border: #e5e5ea solid 1px;
  border-radius: 8px;
  padding: 5px 12px;
  background-color: #ffffff;
}

button.view-plans-button:hover {
  border: #6750a4 solid 1px;
  background-color: #ffffff;
  /* box-shadow:  0px 2px 7px rgba(209, 206, 206, 0.5); */
}

.billing-subscription-edit {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 40px;
  color: #7a7a7a;
  align-items: flex-end;
}

.billing-subscription-edit-leftside {
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: flex-start; /* Aligns children to the left */
}

.billing-subscription-cancelled {
  color: #7a7a7a;
  font-weight: 500;
}

.billing-update-payment-btn,
.billing-cancel-subscription-btn {
  color: #7a7a7a;
  background: 0 0;
  border: none;
  font-weight: 600;
  display: inline;
}

.billing-update-payment-btn:hover,
.billing-cancel-subscription-btn:hover {
  color: #2a2a2a;
  cursor: pointer;
  text-decoration: underline;
}

.resuCredits {
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  padding: 10px;
  border-radius: 8px;
  border: #6750a4 solid 1px;
  margin-bottom: 40px;
}
.resuCredits img {
  margin-right: 10px;
  width: 24px;
  height: 24px;
}

.payment_item {
  width: 100%;
  margin: 20px 0;
  padding: 20px 30px;
  /* border: 1px solid #E5E5EA; */
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  background-color: #ffffff;
}
.item_price_row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
}
.timestamp_row {
  font-size: 14px;
  color: #7a7a7a;
  font-weight: 400;
}
.toggle_button {
  font-size: 16px;
  font-weight: 600;
  border: none;
  color: #7a7a7a;
  margin-bottom: 20px;
  background-color: transparent;
}

.toggle_button:hover {
  color: #2a2a2a;
  cursor: pointer;
}
/* ------------------------------revision history --------------------------------- */
.reviHistory {
  margin: 20px 0 0 50px;
}
.revi_list {
  margin-top: 50px; /* Add margin to position it */
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap; /* Allows items to wrap to the next line if needed */
  width: 70%;
  gap: 10px; /* Use pixels for consistent spacing */
}

.letterHistory {
  margin-top: 50px;
  margin-bottom: 100px;
}

.letterHistory.move-up {
  margin-top: 0px;
}
.revi-item {
  flex: 1 1 200px;
  margin: 10px;
  max-width: 300px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.revi-item:hover {
  cursor: pointer;
}

.history-resume-preview {
  width: 175px;
  height: 220px;
  /* background-color: #ffffff; */
  border-radius: 8px;
  margin-bottom: 10px;
  border: 1px solid #e5e5ea;
  box-shadow: 3px 2px 7px rgba(227, 227, 227, 0.5);
  background-image: url("/static/img/resume-background.png");
  background-size: cover; /* Resize the image to cover the entire div */
  background-repeat: no-repeat; /* Prevent the image from repeating */
  background-position: center center; /* Center the image */
}

.history-cl-preview {
  width: 175px;
  height: 220px;
  /* background-color: #ffffff; */
  border-radius: 8px;
  margin-bottom: 10px;
  border: 1px solid #e5e5ea;
  box-shadow: 3px 2px 7px rgba(227, 227, 227, 0.5);
  background-image: url("/static/img/coverletter-background.png");
  background-size: cover; /* Resize the image to cover the entire div */
  background-repeat: no-repeat; /* Prevent the image from repeating */
  background-position: center center; /* Center the image */
}

.history-resume-preview:hover,
.history-cl-preview:hover {
  border: 1px solid #6750a4;
  /* box-shadow:  0 -20px 6px rgba(236, 236, 236, 0.25) inset; */
}

.text p {
  line-height: 1.2;
  padding-top: 0;
  margin-top: 5px;
}

.text p:first-child {
  font-weight: 600;
  font-size: 14px;
}

.text p:nth-child(2) {
  font-size: 12px;
  color: #7a7a7a;
}
/* ------------------------------My profile --------------------------------- */
.currentData {
  margin: 50px 0 0 50px;
  width: 92%;
}

/* ------------------------------Modal Popup --------------------------------- */
.delete-resume-modal-profile,
.billing-modal-profile {
  background-color: #fff;
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  width: 30%;
  padding: 20px;
  display: flex;
  position: fixed;
  top: 28%;
  left: 36%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  z-index: 200;
}

.delete-resume-modal-profile-content,
.billing-modal-profile-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #fff;
  border-radius: 5px;
  width: 400px;
}

.delete-resume-modal-profile-content img,
.billing-modal-profile-content img {
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
}

.delete-resume-modal-profile-content h2,
.billing-modal-profile-content h2 {
  font-size: 20px;
  color: #2a2a2a;
  font-weight: 700;
  margin-bottom: 20px;
}

.delete-resume-modal-profile-content p,
.billing-modal-profile-content p {
  font-size: 16px;
  color: #7a7a7a;
  margin-bottom: 20px;
  font-weight: 500;
  line-height: 1.3;
}

.delete-resume-confirm-button-yes,
.billing-confirm-button-yes {
  color: #2a2a2a;
  cursor: pointer;
  background-color: #e5e5ea;
  border: 1px solid #e5e5ea;
  border-radius: 8px;
  margin: 5px 18px;
  padding: 12px 50px;
  font-weight: 600;
  width: 90%;
}

.delete-resume-confirm-button-no,
.billing-confirm-button-no {
  color: #ffffff;
  cursor: pointer;
  background-color: #6750a4;
  border: 1px solid #6750a4;
  border-radius: 8px;
  margin: 5px 18px;
  padding: 12px 50px;
  font-weight: 600;
  width: 90%;
}

.dimmer-cancel,
.dimmer-delete {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
  z-index: 100; /* Ensure it's behind the modal but above other elements */
  display: none; /* Hidden by default */
}

.dimmer-cancel.active,
.dimmer-delete.active {
  display: block;
}

.myapplication-control {
  margin: 20px 0 0 50px;
  width: 92%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  border-radius: 9999px; /* 修改：使整个控制栏变为椭圆形 */
  margin-bottom: 20px;
}

.myapplication-right-group {
  display: flex;
  align-items: center;
}

.myapplication-control button {
  cursor: pointer;
}

.myapplication-add-application-container {
  display: inline-flex;
  align-items: center;
  background: white;
  border: 1px solid #ccc;
  border-radius: 9999px;
  padding: 0 12px 0 8px;
  width: 160px;
  height: 30px;
}
.myapplication-search-container {
  display: inline-flex;
  align-items: center;
  background: white;
  border: 1px solid #ccc;
  border-radius: 9999px;
  padding: 0 12px 0 8px;
  width: 200px;
  height: 30px;
  margin-left: 15px;
}

.myapplication-filter-container {
  display: inline-flex;
  align-items: center;
  background: white;
  border: 1px solid #ccc;
  border-radius: 9999px;
  padding: 0 12px 0 8px;
  width: 85px;
  height: 30px;
  margin-left: 15px;
}

.myapplication-sort-container {
  display: inline-flex;
  align-items: center;
  background: white;
  border: 1px solid #ccc;
  border-radius: 9999px;
  padding: 0 12px 0 8px;
  width: 210px;
  height: 30px;
  margin-left: 15px;
}

.myapplication-add-application-container:hover,
.myapplication-search-container:hover,
.myapplication-filter-container:hover,
.myapplication-sort-container:hover {
  background-color: #e2e2e2;
  cursor: pointer;
}

.myapplication-control .add-application-button {
  margin-left: 0;
}
.myapplication-custom-input::placeholder {
  color: #aaaaaa;
  font-weight: 600;
}

.myapplication-divider {
  height: 1px;
  background-color: #e0e0e0; /* 灰色 */
  margin: 20px 0 0 50px;
  width: 92%;
}

.myapplication-applications {
  margin: 50px 0 0 50px;
  width: 92%;
  margin-top: 20px;
}

.dashboard-logo-api {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;
}

.dashboard-logo-api a {
  font-size: 12px;
  color: #bababa;
  text-decoration: none;
}

.application-history-temp-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.temp-history-btn {
  border: none;
  background-color: #f9fbfc;
  margin-top: 50;
  text-decoration: underline;
}

@media (max-width: 1021px) {
  .myapplication-control {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }

  .myapplication-right-group {
    width: 100%;
    flex-wrap: wrap;
    gap: 10px;
  }

  .myapplication-add-application-container {
    width: 100%;
    max-width: 160px;
  }

  .myapplication-search-container,
  .myapplication-filter-container,
  .myapplication-sort-container {
    width: auto;
    flex: 1;
    min-width: 160px;
    margin-left: 10px;
  }

  .myapplication-right-group > *:first-child {
    margin-left: 0;
  }
}

@media (max-width: 1024px) {
    .db_contents {
      width: 100%;
    }
  
    .db_sidebar {
      top: 0;
      left: 0;
      position: fixed;
      z-index: 999999999;
      height: 100%;
      background-color: #ffffff;
    }
  
    /* Main box responsive adjustments */
    .db_main-box {
      width: 100%;
      padding: 0 15px;
    }
  
    .db_main-box h4 {
      margin: 30px 0 0 20px;
      font-size: 18px;
    }
  
    /* My Resume section */
    .currentData {
      margin: 30px 0 0 20px;
      width: 100%;
    }
  
    .blank_box {
      padding: 0 15px;
    }
  
    .blank_box img {
      margin-top: 80px;
      width: 80px;
      height: 80px;
    }
  
    /* Application History section */
    .myapplication-control {
      margin: 20px 0 0 20px;
      width: 90%;
      flex-direction: column;
      align-items: flex-start;
      gap: 15px;
    }
  
    .myapplication-right-group {
      width: 100%;
      flex-wrap: wrap;
      gap: 10px;
    }
  
    .myapplication-search-container,
    .myapplication-filter-container,
    .myapplication-sort-container {
      margin-left: 0;
      width: 100%;
    }
  
    .myapplication-divider {
      margin: 20px 0 0 20px;
      width: 90%;
    }
  
    .myapplication-applications {
      margin: 20px 0 0 20px;
      width: 90%;
    }
  
    /* Billing section */
    #resuHistory {
      margin: 30px 0 0 20px !important;
      width: 90% !important;
    }
  
    .billing-credit-balance {
      padding: 15px;
    }
  
    .billing-subscription-edit {
      flex-direction: column;
      align-items: flex-start;
      gap: 15px;
    }
  
    .payment_item {
      padding: 15px;
    }
  
    /* Modal adjustments */
    .delete-resume-modal-profile,
    .billing-modal-profile {
      width: 90%;
      left: 5%;
      top: 20%;
    }
  
    .delete-resume-modal-profile-content,
    .billing-modal-profile-content {
      width: 100%;
      padding: 15px;
    }
  }