.about-us {
  height: 100%;
  width: 100%;
}
.about-us-content {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about-us-box1 {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.bg-logo-img {
  width: 180px;
  height: 180px;
  top: 193px;
  left: 550px;
  gap: 0px;
  opacity: 15%;
}

.about-us-header {
  width: 695px;
  height: 108px;
  top: 138px;
  left: 50%;
  transform: translateX(-50%);
  gap: 0px;
  opacity: 0px;
  font-family: Open Sans;
  font-size: 40px;
  font-weight: 700;
  line-height: 54.47px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  position: absolute;
}

.about-us-box2 {
  width: Fixed (357px) px;
  height: Hug (81px) px;
  top: 499px;
  left: 462px;
  gap: 10px;
  margin-bottom: 80px;
  margin-top: 120px;
}

.about-us-text {
  width: 357px;
  height: 28px;
  gap: 0px;
  opacity: 0px;
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 27.24px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #7a7a7a;
}

.about-us-part-of-logos {
  width: Fill (357px) px;
  height: Hug (43px) px;
  gap: 50px;
  display: flex;
  justify-content: space-between;
}

.pap-logo {
  width: 158px;
  height: 43px;
  background-color: transparent;
}

.gt-logo {
  width: 149px;
  height: 43px;
  background-color: transparent;
}

.about-us-box3 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: Hug (1, 043px) px;
  height: Hug (428px) px;
  top: 659px;
  left: 119px;
  gap: 40px;
  opacity: 0px;
}

.about-us-box3 img {
  width: 321px;
  height: 428px;
  border-radius: 8px 0px 0px 0px; /* Apply the rounded corners to all images */
}

@media (max-width: 1024px) {
  .about-us-box1 {
    width: 97%;
    margin-bottom: 90px;
  }

  .about-us-header {
    width: 100%;
    font-size: 30px;
  }
  .about-us-box3 {
    flex-direction: column;
  }
}
