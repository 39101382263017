.new-revision-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 100vh; /* 确保有足够的高度 */
}

.new-revision-contents {
    background-color: #FFFFFF;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
    align-content: stretch;
    flex-wrap: nowrap;
    place-content: stretch;
    min-height: calc(100vh - 64px); /* 减去顶部导航的高度 */
}