.checkout-container {
    display: flex;  /* Enables Flexbox */
    justify-content: flex-start; /* Aligns child elements (the form) to the end (right) */
    height: 100vh; /* Sets the container height to fill the viewport */
    margin-top: 60px;
  }
  
  #payment-form {
    width: 100%; /* Form takes up half of the container's width */
    /* Keep your existing form styles here */
  }

  button.submit-payment-button{
    margin-top: 20px;
    padding: 10px 20px;
    width: 100%;
    cursor: pointer;
    background-color: #6750A4;
    color: #ffffff;
    font-size: 18px;
    font-weight: 700;
    border-radius: 30px;
    border: none;

  }


  .payment-container{
    display: grid;
    grid-template-columns: repeat(12, 1fr); /* 12-column grid system */
    height: auto;
    background-color:#ffffff;
    
  }

  .left-container{
    background-color:#ffffff;
    margin-top: 90px;
    padding: 30px;
    grid-column: span 5; /* Sidebar spans 5 columns */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: 100vh;
  }

  .select-price-container{
    background-color:#ffffff;
    margin-left: 90px;
  }

  .profile-back-button{
    margin-bottom: 10px;
  }

  .content-title{
    color:#000;
    font-family: "Open Sans";
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 40px;
  }

  .payment-content-title{
    color:#000;
    font-family: "Open Sans";
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 40px;
    margin-top: 34px;
  }

  .price-card-container{
    
  }

.price-card-unselected{
  width: 400px;
  height: 120px;
  border: 2px solid #E5E5EA;
  border-radius: 8px;
  padding: 20px 20px;
  margin-bottom: 28px;
}

.price-card-unselected:hover{
  box-shadow: 0 4px 4px #eee8ef;
}

.price-card-unselected.selected{
  width: 400px;
  height: 120px;
  border: 2px solid #381E72;
  border-radius: 8px;
  padding: 20px 20px;
  box-shadow: 0 4px 4px #eee8ef;
  margin-bottom: 28px;
}



.payment-title{
  color:#2a2a2a;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 12px;
}

.payment-price{
  color: #381E72;
  font-size: 36px;
  font-weight: 700;
  font-family: "Open Sans";
}


.right-container{
  margin-top: 90px;
  padding: 30px;
  background-color:#FAF9FF;
  grid-column: span 7; /* Content spans 5 columns */
  display: flex;
  flex-direction: column;
}

.payment-details-container{
  margin-left: 90px;
  margin-right: 90px;

}

.payment-text-medium{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 500;
  color: #000;
  margin-top: 18px;
}

.payment-text-small{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 500;
  color: #aaaaaa;
  margin-top: 18px;
}

.divider{
  background-color: #cccccc;
  height: 1px;
  margin-top: 12px;
}

.checkout-container .error-message {
  color: red;
  margin-bottom: 20px;
  text-align: center;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75); /* Dark background */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 500px;
  text-align: center;
}

button.return-to-home{
  margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
}