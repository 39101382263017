body {
  justify-content: center;
  align-items: center;
}

html {
  height: 0 !important;
}

.contact {
  background: linear-gradient(
    180deg,
    rgb(103, 80, 164) 0%,
    rgb(103, 80, 164) 62.5%,
    rgb(250, 249, 255) 62.51%,
    rgb(250, 249, 255) 100%
  );
  background-color: #faf9ff;
  align-items: center;
  width: 100%;
  min-height: 100vh;
}

.back-button-white{
  color: white;
}
.contact-content{
  padding: 40px 13% 40px;
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 30px;
  position: relative;
  width: 100%;
}

.contact-title {
  color: #ffffff;
  font-family: "Open Sans", Helvetica;
  font-size: 48px;
  font-weight: 400;
  line-height: normal;
  word-wrap: 'break-word';
}
.contact-email{
  font-family: "Open Sans", Helvetica;
  font-size: 22px;
  font-weight: 600;
  color: #fff843;
}

.contact-dc {
  display: flex;
  align-items: center;
  font-family: "Open Sans", Helvetica;
  font-size: 22px;
  font-weight: 600;
  left: 50px;
  color: #ffffff;
}

.text-wrapper-dc {
  color: #fff742;
  text-decoration: underline;
}
.contact-dc-img{
  width: 35px;
  color: white;
  margin-right: 10px;
}
.feedback-box{
  margin: 25px 13% 57px;
  background-color: white;
  height: 700px;
  border-radius: 10px;
}

.contact-dc-text{
    line-height: 1.4;
}

@media (max-width: 768px) {
  .contact-content {
    padding: 30px 20px;
    gap: 20px;
  }

  .contact-title {
    font-size: 32px;
  }

  .contact-email {
    font-size: 18px;
  }

  .contact-dc {
    font-size: 16px;
    align-items: flex-start;
  }

  .contact-dc-img {
    width: 24px;
    margin-top: 3px;
  }

  .contact-dc-text {
    line-height: 1.5;
  }

  .feedback-box {
    margin: 20px 20px 40px;
    height: 600px;
  }

  /* Improve back button touch target */
  .back-button-white {
    padding: 15px;
    margin-left: 5px;
  }

  .back-button-img {
    width: 20px;
    height: 20px;
  }
}

/* Additional improvements for very small screens */
@media (max-width: 380px) {
  .contact-title {
    font-size: 28px;
  }

  .contact-email {
    font-size: 16px;
  }

  .contact-dc {
    font-size: 14px;
  }

  .feedback-box {
    margin: 15px 15px 30px;
    height: 500px;
  }
}

/* Ensure proper box model calculation */
* {
  box-sizing: border-box;
}