.add-application-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 20px; /* Added padding for space around the modal */
    cursor: auto;
}

.add-application-modal-content {
    background-color: #fff;
    border-radius: 12px;
    flex-direction: column;
    width: 60%;
    max-width: 1400px;
    height: 80%;
    max-height: 750px;
    padding: 0;
    display: flex;
    overflow: hidden;
    box-shadow: 0 4px 20px #00000026;
}

.popup-styled-dropdown-container {
    top: 100%;
    z-index: 10;
    box-sizing: border-box;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 6px;
    /* width: 60%; */
    width: 100%;
    /* max-height: 250px; */
    /* margin-top: -10px; */
    position: absolute;
    overflow-y: auto;
    box-shadow: 0 4px 8px #0000001a;
}

.add-application-modal-scroll-content {
    padding: 30px; /* Added padding inside for content */
    overflow-y: auto; /* Scrollable area */
    flex-grow: 1;
    border-radius: inherit; /* Ensures inherited border-radius */
    box-sizing: border-box; /* Ensures padding doesn't increase width */
    cursor: auto;
}

.add-application-title {
    color: #2c3e50;
    font-size: 16px;
    margin-bottom: 30px;
    font-weight: 600;
}

.add-application-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

 /* Normal state for input-error class (when input is empty) */
.input-error {
    border-color: #B00000 !important; /* Red border */
    border-width: 2px !important; /* Ensure border width */
    box-shadow: none !important; /* Disable box-shadow */
}

/* Ensure the red border remains when the input is in focus */
.input-error:focus {
    border-color: #B00000 !important; /* Keep red border */
    border-width: 2px !important; /* Ensure border width */
    box-shadow: none !important; /* Disable any box-shadow */
}

.add-application-input:focus {
    outline: none;
    border-color: #3498db;
    box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.2);
}

.resume-placeholder {
    color: #34495e;
    background-color: #f4f4f4;
    border-radius: 6px;
    padding: 10px;
    font-size: 16px;
}

.add-application-button-group {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    gap: 20px;
}

.global-save-button {
    text-align: center;
    color: #fff;
    background-color: #6750a4;
    border: 2px solid #6750a4;
    border-radius: 8px;
    width: 100%;
    padding: 12px 20px;
    font-size: 16px;
    font-weight: 700;
}

.global-save-button:hover {
   background-color: #381E72;
}

.global-save-button:disabled {
    cursor: not-allowed;
    box-shadow: none;
    background-color: #c7c7c7;
    border: 2px solid #c7c7c7;
  }

.global-cancel-button {
    text-align: center;
    color: #2A2A2A;
    background-color: #E5E5EA;
    border: none;
    border-radius: 8px;
    width: 100%;
    padding: 12px 20px;
    font-size: 16px;
    font-weight: 700;
}

.add-application-submit-button,
.add-application-close-button {
    padding: 12px 24px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 1em;
    font-weight: 500;
    transition: all 0.3s ease;
}

.add-application-submit-button {
    background-color: #2ecc71;
    color: white;
}

.add-application-submit-button:hover {
    background-color: #27ae60;
}

.add-application-close-button {
    background-color: #e74c3c;
    color: white;
}

.add-application-close-button:hover {
    background-color: #c0392b;
}

.status-slider {
    width: 100%;
    margin-top: 10px;
}

.slider-labels {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    font-size: 0.9em;
}


.add-application-form-row {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.add-application-form-status-row{
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    align-items: flex-start;
}
  


.add-application-form-btns,
.add-application-form-group {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.add-application-form-date{
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    
}


.add-application-form-status{
    display: flex;
    flex-direction: column;
    min-width: 350px;
}

.add-application-form-timeline{
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-right: 40px;
}

.add-application-label {
    font-weight: 600;
    margin-bottom: 8px;
    color: #2A2A2A;
    font-size: 16px;
    width: fit-content;
}

.add-application-input {
    width: 100% !important;
    padding: 10px !important;
    border: 1px solid #bdc3c7;
    border-radius: 6px !important;
    font-size: 1em !important;
    transition: border-color 0.3s, box-shadow 0.3s !important;
    box-sizing: border-box !important;
    margin: 0;
}

.add-application-input:focus {
    border-color: #6750A4 !important;
    outline: none !important;
    box-shadow: 0 0 0 2px rgba(103, 80, 164, 0.2) !important;
}


.add-application-input-temp {
    padding: 8px;
    border: 1px solid #bdc3c7;
    border-radius: 6px;
    font-size: 1em;
    transition: border-color 0.3s, box-shadow 0.3s;
    box-sizing: border-box;
    margin: 0;
    width: 140px;
    cursor: pointer;
}

.add-application-input-temp:focus,
.add-application-input-temp:focus-within {
    border-color: #6750A4;
    outline: none;
    box-shadow: 0 0 0 2px rgba(103, 80, 164, 0.2);
}



.application-status-dropdown{
    position: relative;
    display: inline-block;
    font: Arial, sans-serif;
    font-size: 16px;
    width: fit-content;
}

.application-status-dropdown-open{
    position: absolute; 
    top: 100%;
    left: 0;
    right: 0; 
    margin-top: '4px';
    background-color: #ffffff;
    width: 140px;
    min-width: 120px; 
	border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    z-index: 1000;
}

.status-timeline-title{
    position: relative; 
    width: 100%;
}

.status-timeline-date{
    position: relative; 
    width: 100%;
}
/* 添加到现有的 CSS 文件中或替换相关部分 */

/* 更新现有的 CSS */

.status-slider-container {
    position: relative;
    width: 100%;
    height: 40px;
    margin-top: 20px;
}
  
  .status-slider {
    -webkit-appearance: none;
    width: 100%;
    height: 2px;
    background: #E2E2E2;
    outline: none;
    opacity: 1;
    transition: opacity .2s;
    position: absolute;
    top: 30%;
    transform: translateY(-50%);
  }
  
  /* 移除滑块按钮的样式 */
  .status-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 0;
    height: 0;
  }
  
  .status-slider::-moz-range-thumb {
    width: 0;
    height: 0;
    border: none;
  }
  
  .status-slider::before {
    content: '';
    position: absolute;
    height: 2px;
    background: #6750A4;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: calc((100% / 3) * var(--slider-value));
    z-index: 1;
  }
  
  .stage-marker {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    top:55%;
    transform: translate(-50%, -50%);
    z-index: 2;
    transition: all 0.3s ease;
  }
  
  .stage-marker.unselected {
    background-color: white;
    border: 2px solid #D0D0D0;
  }
  
  .stage-marker.selected {
    background-color: #6750A4;
    border: 2px solid #6750A4;
  }
  
  .stage-marker.rejected {
    background-color: #AAAAAA;
    border: 2px solid #AAAAAA;
  }
  
  .stage-marker.rejected.selected {
    background-color: #AAAAAA;
    border: 2px solid #AAAAAA;
  }
  
  .stage-marker.rejected.unselected {
    display: none;
  }

  .add-applications-status-dropdown-item{
    cursor: pointer;
    padding: 8px;
    display:  flex;
    align-items: center;
  }

.add-applications-status-dropdown-item:hover {
    background-color: #F6F6F6;
}

/* Ensure the Quill container respects the height and fills the space */
.popup-custom-quill-editor .ql-container {
    max-height: 100px; /* Make the container fill the height */
}

/* Override the inner editor's style to match your rectangle design */
.popup-custom-quill-editor .ql-editor {
    font-family: "Open Sans", Helvetica; /* Font family */
    font-size: 13px; /* Font size */
    line-height: 17.7px; /* Line height */
    min-height: 100px; /* Fill the parent height */
    max-height: 250px;
    padding: 10px; /* Apply padding to match the rectangle style */
    letter-spacing: 0; /* Adjust letter spacing */
    overflow-y: auto; /* Ensure vertical scrolling if the text is too long */
    box-sizing: border-box; /* Ensure padding and border are considered */
}

/* Ensure the toolbar matches your style (optional) */
.popup-custom-quill-editor .ql-toolbar {
    border-radius: 10px 10px 0 0; /* Match the border radius */
    font-family: "Open Sans", Helvetica;
    font-size: 16px;
    padding: 5px 10px; /* Optional: Adjust padding to match design */
}

/* Customize Quill placeholder style */
.popup-custom-quill-editor .ql-editor::before {
    color: #AAAAAA; /* Match placeholder color */
    font-family: "Open Sans", Helvetica; /* Match font-family */
    font-size: 13px; /* Match font-size */
    font-weight: 400; /* Match font-weight */
    font-style: normal; /* Remove italic style */
    opacity: 1; /* Ensure the placeholder is visible */
}

@media (max-width: 768px) {
    .add-application-modal-content {
        width: 90%;
    }
}