.resuName {
    width: 86%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    padding: 20px 30px;
    box-sizing: border-box;
    /* border: 1px solid #E5E5EA; */
    background-color: #FFFFFF;
    margin-bottom: 10px;
}

.resuName h1 {
    margin: 0;
   font-size: 16px;
   font-weight: 600;
}

.resuName_icons{
    display: flex;
    flex-direction: row;
    gap:20px;
}

.resuName img{
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.personalTitle .dashboard-edit-icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.dashboard-edit-icon{
    background-size: cover;
    background-position: center;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.Infor{
    width: 86%;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    padding: 20px 30px;
    gap: 8px;
    /* border: 1px solid #E5E5EA; */
    background-color: #FFFFFF;
    margin-top: 40px;
    margin-bottom: 40px;
}

.Infor:hover{
    /* border: 0.5px solid #E5E5EA; */
    box-shadow: rgba(203, 207, 211, 0.2) 0px 4px 10px;
    cursor: pointer;
}

.Infor:hover .dashboard-edit-icon{
    opacity: 1;
    background-image: url('/static/img/edit_resume_pen.png');
}

.personalTitle{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}
.personalTitle p{
    margin: 0;
    font-size: 16px;
    font-weight: 600;
}

.personalTitle .current-resume-section-header{
    display: inline-flex;
    align-items: center;
}

.personalTitle .current-resume-section-header img{
    width: 22px;
    height: 22px;
    margin-right: 8px;
}

.item{
    margin-bottom: 8px;
}
.currentSkills{
    line-height:1.5 ;
}

.dashboard-remove-button {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;  /* Changes the cursor to a pointer to indicate it's clickable */
}